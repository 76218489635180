.MoviesPage {
    min-height: 100vh;
    background: #111;
}

.moviesInnerPage {
    width: 70%;
    margin: 0 auto;
    min-height: 900px;
    margin-bottom: 50px;
}

.searchField {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    .searchInnerField {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
            color: #fff;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }
}

.movieHero {
    height: 350px;
    background-image: url("../assets/images/MovieBG.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.moviesListing {
    margin-top: 10px;
    h6 {
        color: #fff;
        font-family: "Inter";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.32px;
    }
}

.moviesListingContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .eachMovie {
        width: 18%;
        transition: box-shadow 0.3s ease;
        padding: 1%;
        cursor: pointer;
        &:hover {
            box-shadow: 0 4px 6px rgba(203, 200, 200, 0.2);
        }
        .movieImage {
            height: 250px;
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        p {
            color: #fff;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
            margin-top: 10px;
        }
    }
}

@media (max-width: 1000px) {
    .moviesInnerPage {
        width: 80%;
        margin-bottom: 100px;
    }
    .moviesListingContainer {
        flex-wrap: wrap;
        gap: 20px;
        .eachMovie {
            width: 200px;
            .movieImage {
                height: 300px;
                width: 100%;
            }
        }
    }
}

@media (max-width: 628px) {
    .moviesInnerPage {
        width: 90%;
    }
}

@media (max-width: 466px) {
    .moviesListingContainer {
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
        .eachMovie {
            width: 300px;
            .movieImage {
                height: auto;
                width: 100%;
            }
        }
    }

    .movieHero {
        height: 250px;
    }
}
