.VouchersContainer {
    min-height: 100vh;
    background: #fff;
}

.vouchersContent {
    width: 80%;
    min-height: 500px;
    margin: 30px auto;
    .headerText {
        color: #000;
        text-align: center;
        font-family: "Inter";
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.54px;
        text-transform: capitalize;
    }
    .voucherContainer {
        display: flex;
        justify-content: space-between;

        .voucherCard {
            width: 300px;
            margin-top: 30px;
            padding: 10px;
            transition: box-shadow 0.3s ease;

            cursor: pointer;
            &:hover {
                box-shadow: 0 4px 6px rgba(203, 200, 200, 0.2);
            }
            .voucherImg {
                width: 280px;
                height: 200px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            p {
                color: #1f1f1f;

                font-family: "Inter";
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.15px;
            }
            .voucherBTNContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70px;
                .voucherBTN {
                    width: 100%;
                    color: #000;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%;
                    letter-spacing: -0.14px;
                    border-radius: 4px;
                    border: 2px solid #000;
                    background: #f0bd2d;
                }
            }
        }

        @media (max-width: 749px) {
            .voucherCard {
                margin: 0 auto;
            }
        }
    }

    @media (max-width: 1129px) {
        .voucherContainer {
            flex-wrap: wrap;
            margin: 100px 0px;
        }
    }
}

@media (max-width: 493px) {
    .vouchersContent {
        width: 90%;
        .headerText {
            font-size: 30px;
        }
    }
}
