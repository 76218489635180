.paymentToLSLContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.inputInnerContainer {
    display: flex;
    justify-content: space-between;
}

.inputAndLabelContainer {
    width: 30%;
    flex-direction: column;
}

.inputAndLabelContainer > input {
    width: 100%;
}

@media (max-width: 633px) {
    .inputInnerContainer {
        flex-direction: column;
        gap: 30px;
    }

    .inputAndLabelContainer {
        width: 100%;
    }
}

@media (max-width: 481px) {
    .headerText {
        text-align: center;
    }

    .inputContainer {
        margin-bottom: 100px;
    }
}
