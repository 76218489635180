.menuDeleteModal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    background-color: #fff;
    // width: 200px;
    // height: 150px;
    padding: 20px;
    max-width: 320px;
    width: 100%;
}

.modalHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalContentText {
    margin: 30px 0px;
}

.modalContentText > h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}

.modalBTNContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.modalBTNContainer > button {
    width: 130px;
}

.resetBTN {
    background-color: #fff;
    border: 2px solid #00bce1;
    color: #00bce1;
}

.resetBTN:hover {
    background-color: #00bce1;
    border: 2px solid #00bce1;
    color: #fff;
}

.modalHeaderContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.icon {
    cursor: pointer;
}
