.frequencyPeriodic {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}
.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.innerFieldContainer1,
.innerFieldContainer8 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.fieldContainer2,
.fieldContainer3,
.fieldContainer4,
.fieldContainer8 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer3,
.radioButtonField2,
.radioButtonField4 {
    width: 25%;
    display: flex;
    flex-direction: column;
}

.checkbox2 {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.checkboxText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.monthsContainer,
.weeksContainer,
.daysContainer {
    margin-top: 30px;
}

.monthsMainContainer,
.mainWeeksContainer,
.mainDaysContainer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.innerMonthsContainer,
.innerDaysContainer {
    display: flex;
    gap: 5px;
    flex-basis: 100px;
    align-items: center;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.fieldContainer5 {
    margin-top: 30px;
}

.outterFieldContainer5 {
    display: flex;
    justify-content: space-between;
}
.innerFieldContainer5 {
    width: 22%;
    display: flex;
    flex-direction: column;
}

.paginationContainer {
    height: 50px;
    display: flex;
    align-items: center;
}

.diabledText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 20px;
}

.fieldContainer6 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer6 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.fieldContainer7 {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.innerFieldContainer7 {
    width: 30%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1292px) {
    .fieldContainer4 {
        flex-wrap: wrap;
        gap: 30px;
    }
    .radioButtonField4 {
        width: 45%;
    }

    .checkbox2 {
        width: 50%;
    }
}

@media (max-width: 1266px) {
    .tableContainer {
        overflow-y: scroll;
    }

    .mainTable {
        width: 1300px;
    }
}

@media (max-width: 1101px) {
    .fieldContainer4 {
        flex-direction: column;
        gap: 30px;
    }
}

@media (max-width: 1088px) {
    .outterFieldContainer5 {
        flex-wrap: wrap;
        gap: 30px;
    }
    .innerFieldContainer5 {
        width: 45%;
    }
}

@media (max-width: 749px) {
    .fieldContainer3,
    .fieldContainer8,
    .fieldContainer7,
    .fieldContainer2,
    .fieldContainer1 {
        flex-direction: column;
        gap: 30px;
    }

    .innerFieldContainer3,
    .innerFieldContainer8,
    .innerFieldContainer7,
    .radioButtonField2,
    .innerFieldContainer1 {
        width: 100%;
    }

    .fieldContainer3 {
        flex-direction: column;
        gap: 30px;
    }
    .innerFieldContainer3,
    .innerFieldContainer6 {
        width: 100%;
    }
    .radioButtonField2,
    .radioButtonField4 {
        width: 100%;
    }

    .fieldContainer6 {
        flex-direction: column;
        gap: 30px;
    }
    .checkbox2 {
        width: 100%;
    }
    .buttonContainer {
        justify-content: space-between;
    }
}

// @media (max-width: 633px) {

// }

@media (max-width: 561px) {
    .checkbox2 {
        width: 100%;
    }

    .fieldContainer1,
    .fieldContainer2,
    .fieldContainer4,
    .outterFieldContainer5 {
        flex-direction: column;
        gap: 30px;
    }

    .innerFieldContainer1,
    .radioButtonField4,
    .innerFieldContainer5,
    .radioButtonField2 {
        width: 100%;
    }

    .monthsMainContainer,
    .mainWeeksContainer,
    .mainDaysContainer {
        justify-content: space-between;
    }
}

@media (max-width: 399px) {
    .monthsMainContainer,
    .mainWeeksContainer,
    .mainDaysContainer {
        justify-content: space-between;
    }

    .headerText {
        text-align: center;
    }
}
