.VoucherRedemptionReceiptTemplate {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.field1 {
    display: flex;
    justify-content: space-between;
}
.field2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer1 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.fieldContainer2 {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.fieldContainer2 {
    display: flex;

    .radioButtonsContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .radioButtonContainer {
            gap: 5px;
            display: flex;
            align-items: center;
        }
    }
}

.radioImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .radioInnerImage {
        display: flex;

        justify-content: center;
    }
    img {
        width: 100px;
        height: 80px;
        object-fit: cover;
        object-position: center;
    }

    p {
        color: var(--shade-black, #000);
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.field3 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}
.radioButtonsContainer3 {
    display: flex;
    justify-content: space-between;
}

.radioButtonContainer3 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.tableSection {
    margin-top: 30px;
}

.active {
    color: var(--perx-green, #81c041);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.inactive {
    color: var(--shade-black, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

@media (max-width: 1300px) {
    .innerTableContainer {
        overflow-x: scroll;
    }
    .mainTable {
        width: 1350px;
    }
}

@media (max-width: 1232px) {
    .fieldContainer2 {
        display: flex;
        flex-direction: column;
        width: 75%;
    }
}

@media (max-width: 578px) {
    .radioButtonsContainer {
        flex-wrap: wrap;
        gap: 30px;
    }

    .fieldContainer2 {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .field2 {
        flex-direction: column;
        gap: 30px;
    }
    .fieldContainer1,
    .fieldContainer2 {
        width: 100%;
    }
    .buttonContainer {
        justify-content: space-between;
    }
}
@media (max-width: 423px) {
    .headerText {
        text-align: center;
    }
}
