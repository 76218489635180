.createCampaign {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.campaignHeader {
    cursor: pointer;
    padding: 10px;
    height: 50px;
    background-color: #cde6b3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    span {
        cursor: pointer;
    }
}

.campaignHeader2 {
    cursor: pointer;
    padding: 10px;
    height: 50px;
    background-color: #cde6b3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    span {
        cursor: pointer;
    }
}

.campaignHeader2Disabled {
    cursor: not-allowed;
    pointer-events: none;
    padding: 10px;
    height: 50px;
    border-top: 1px solid var(--shade-gray-1, #919ba4);
    border-bottom: 1px solid var(--shade-gray-1, #919ba4);
    background: var(--shade-gray-3, #f0f3f9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    span {
        cursor: pointer;
    }
}

.basicInfoForm {
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.fieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldInnerContainer1 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContent3 {
    margin-top: 30px;
    width: 100%;
}

.fieldInnerContainer3 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.branchContainer {
    position: absolute;
    min-width: 100px;

    width: 65%;
    overflow-y: scroll;
    min-height: 100px;
    height: 100px;
    padding: 10px;
    background-color: #fff;
    z-index: 2;
    box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.18);
}

.branchInnerContainer {
    .activedItem {
        padding: 10px;
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }

    .disabledItem {
        color: #919ba4;
        background-color: #f5f5f5;
        cursor: not-allowed;
        pointer-events: none;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;
    }
}

.searchResultContainer {
    width: 100%;
    border: 1px solid #ced4df;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
}

.searchResultContainerDisabled {
    width: 100%;
    border: 1px solid #ced4df;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #f0f3f9;
}

.option1Container {
    width: 250px;
}

.option2Container {
    width: 250px;
    margin-top: 30px;
}
.option3Container {
    width: 300px;
    margin-top: 30px;
}

.sweepstakesContainer,
.rewardsContainer {
    margin-top: 30px;
}

.field1 {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.field2Container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.field2 {
    width: 45%;
}

.field3Container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.field3 {
    width: 30%;
    input {
        width: 100%;
    }
}

.option4Container {
    margin-top: 30px;
    width: 45%;
}
.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

@media (max-width: 655px) {
    .field2Container,
    .field3Container,
    .fieldContainer1,
    .fieldContainer2 {
        flex-direction: column;
        gap: 30px;
    }
    .field2,
    .option1Container,
    .field3,
    .option4Container,
    .option2Container,
    .option3Container,
    .fieldInnerContainer1 {
        width: 100%;
    }

    .buttonContainer {
        width: 100%;
        justify-content: space-between;
    }

    .branchContainer {
        width: 80%;
    }
}
