.radioButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    gap: 5px;
}

.radioButtons_ {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    gap: 5px;
}

.radioButton {
    display: flex;
    align-items: center;

    margin-right: 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    gap: 5px;

    // input[type="radio"] {
    //     display: none;
    // }

    &.active {
        color: #fff;

        input[type="radio"] {
            border: 1px solid #00bce1;
            background-color: #00bce1;
        }
    }
}

.radioButtonSingle {
    display: flex;
    align-items: center;
    width: 120px;
    margin-right: 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    gap: 5px;

    // input[type="radio"] {
    //     display: none;
    // }

    &.active {
        color: #fff;

        input[type="radio"] {
            border: 1px solid #00bce1;
            background-color: #00bce1;
        }
    }
}

.radioButton_ {
    display: flex;
    align-items: center;
    min-width: 150px;

    margin-right: 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    gap: 5px;

    // input[type="radio"] {
    //     display: none;
    // }

    &.active {
        color: #fff;

        input[type="radio"] {
            border: 1px solid #00bce1;
            background-color: #00bce1;
        }
    }
}

.myCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #00bce1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checked {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.uncheck {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

// @media (max-width: 1088px) {
//     .radioButtons {
//         gap: 15px;
//         flex-wrap: wrap;
//     }_
// }

@media (max-width: 609px) {
    .radioButtons_ {
        gap: 15px;
        flex-wrap: wrap;
    }
}
