.dashboardContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.dashboardText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.leftMainContainer {
    width: 48%;
}

.leftInnerContainer {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.rightMainContainer {
    width: 48%;
}

.premiumInnerContainer {
    display: flex;

    align-items: center;
    height: 100%;

    gap: 5px;
    margin-left: 40px;
}

.premiumContainer {
    height: 120px;
    width: 48%;
    background-color: #cde6b3;
}

.premiumPlusContainer {
    height: 120px;
    width: 48%;
    background: #99e4f3;
}

.coalitionContainer {
    height: 120px;
    width: 48%;
    background: #f2b9d1;
    margin-top: 4%;
}

.coalitionInnerContainer {
    display: flex;

    align-items: center;
    height: 100%;

    gap: 5px;
    margin-left: 40px;
}

.enterpriseContainer {
    height: 120px;
    width: 48%;
    background: #e5d1ab;
    margin-top: 4%;
}

.enterpriseInnerContainer {
    display: flex;

    align-items: center;
    height: 100%;

    gap: 5px;
    margin-left: 40px;
}

.premiumPlusInnerContainer {
    display: flex;

    align-items: center;
    height: 100%;

    gap: 5px;
    margin-left: 40px;
}

.boxHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.boxText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.boxNumber {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.rightInnerContainer {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.bankContainer {
    height: 120px;
    width: 48%;
    background: #e5d1ab;
    // margin-top: 4%;
}
.bankInnerContainer {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 5px;
    margin-left: 40px;
}

.retailContainer {
    height: 120px;
    width: 48%;
    background: #81c041;
}

.retailInnerContainer {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 5px;
    margin-left: 40px;
}

.fmcgContainer {
    height: 120px;
    width: 48%;
    background: #ff0000;
    margin-top: 4%;
}

.fmcgInnerContainer {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 5px;
    margin-left: 40px;
}

.telcoContainer {
    height: 120px;
    width: 48%;
    margin-top: 4%;
    background: #00bce1;
}

.telcoInnerContainer {
    display: flex;

    align-items: center;
    height: 100%;
    gap: 5px;
    margin-left: 40px;
}

.totalsContainer {
    width: 100%;
    margin: 3% auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 250px;
    background-color: red;
    background: #ffffff;
    border: 1px solid #ced4df;
    border-radius: 5px;
    padding: 20px;
}

.totalContainer {
    height: 47px;
    display: flex;

    align-items: center;
    width: 100%;
    border-top: 1px solid #ced4df;
    border-bottom: 1px solid #ced4df;
}

.totalTextContainer {
    background-color: #f0f3f9;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.totalNumberContainer {
    width: 50%;
    height: 100%;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.belowMainContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.leftBelowMainContainer {
    width: 48%;
}

.rightBelowMainContainer {
    width: 48%;
}

.totalTextContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.totalNumberContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

.lastSection {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    height: 200px;
}

.leftLastSection {
    width: 48%;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #5ed700;
}

.rightLastSection {
    width: 48%;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #ffbc00;
}

.lastSectionTextContainer {
    display: flex;
    align-items: center;
    margin-left: 40px;
    gap: 15px;
}

.redeemAndIssuedPoints > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.redeemAndIssuedPoints > h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

@media (max-width: 1024px) {
    .premiumInnerContainer {
        margin-left: 20px;
    }

    .premiumPlusInnerContainer {
        margin-left: 20px;
    }

    .coalitionInnerContainer {
        margin-left: 20px;
    }

    .enterpriseInnerContainer {
        margin-left: 20px;
    }

    .bankInnerContainer {
        margin-left: 20px;
    }

    .retailInnerContainer {
        margin-left: 20px;
    }

    .fmcgInnerContainer {
        margin-left: 20px;
    }
    .telcoInnerContainer {
        margin-left: 20px;
    }
}

@media (max-width: 685px) {
    .mainContainer {
        flex-direction: column;
    }

    .belowMainContainer {
        flex-direction: column;
    }

    .lastSection {
        flex-direction: column;
    }
    .leftMainContainer {
        width: 100%;
    }
    .rightMainContainer {
        width: 100%;
        margin-top: 30px;
    }

    .leftBelowMainContainer {
        width: 100%;
        margin-top: 30px;
    }

    .rightBelowMainContainer {
        width: 100%;
    }

    .leftLastSection {
        width: 100%;
    }

    .rightLastSection {
        width: 100%;
        margin-top: 30px;
    }

    .lastSection {
        margin: 30px 0px;
        display: flex;
        justify-content: space-between;
        height: 250px;
    }
}
