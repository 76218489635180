.companySurveyManagement {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.innerFieldContainer1 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.notificationText {
    height: 100px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.paginationContainer {
    display: flex;
    height: 50px;
    align-items: center;
}

@media (max-width: 660px) {
    .fieldContainer1,
    .fieldContainer2 {
        flex-direction: column;
        gap: 30px;
    }
    .innerFieldContainer1 {
        width: 100%;
    }

    .buttonContainer {
        justify-content: space-between;
    }

    .tableContainer {
        overflow-y: scroll;
    }

    .mainTable {
        width: 670px;
    }
}
