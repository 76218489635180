.Dashboard {
    // height: 100%;
    background-color: #fff;
    color: black;
    width: 100%;
    padding-top: 10px;
    min-height: 800px;
}
//

.parentContainer {
    display: flex;
    width: 100vw;
    // height: 100vh;

    // flex: 2;
}

.sidebarCon {
    color: #fff;
    min-height: 100vh;
    width: 250px;
    overflow: auto;
    color: #fff;
    border-right: 1px solid #ced4df;
}

.sidebarConAdjust {
    color: #fff;
    min-height: 100vh;
    width: 100px;
    overflow: auto;
    color: #fff;
    border-right: 1px solid #ced4df;
}

.navbarCon {
    width: calc(100% - 250px);

    // height: 100%;
    // width: 100%;
}

.navbarConAdjust {
    width: calc(100% - 100px);
    // height: 100%;

    // width: 100%;
}

@media (max-width: 991px) {
    .sidebarCon {
        display: none;
    }

    .navbarCon {
        width: 100%;
    }
}
