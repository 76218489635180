  .uploadContainer {
    display:flex;
    border:2px solid #CED4DF;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .uploadLabel {
    background: #99E4F3;
    margin:'10px';
    padding:10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  /* Hide the original file input */
  .upload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  