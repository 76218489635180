.navbarContainer {
    height: 70px;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #ced4df;
    border-bottom: 1px solid #ced4df;
    border-right: 1px solid #ced4df;

    display: flex;
    align-items: center;
    z-index: 200;
    width: calc(100% - 250px);
    position: fixed;
    top: 0;
}

.searchContent {
    position: absolute;
    top: -70;
    min-width: 100px;
    width: 35%;
    min-height: 100px;
    padding: 10px;
    background-color: #fff;
    z-index: 2;
    box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.18);
}

.navbarContainerAdjust {
    height: 70px;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #ced4df;
    border-bottom: 1px solid #ced4df;
    border-right: 1px solid #ced4df;
    // width: 100%;
    display: flex;
    // flex-grow: 1;
    align-items: center;

    z-index: 200;
    width: calc(100% - 100px);
    position: fixed;
    top: 0;
    // left: 250px;
}

.navbarInnerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

li {
    list-style: none;
}

.searchBarContainer {
    width: 40%;
}

.searchField {
    display: flex;
    align-items: center;

    width: 100%;
    border: 1px solid #ced4df;
    height: 47px;
    padding-left: 10px;
}

.searchInput {
    height: 45px;
    background: #ffffff;
    /* border: 1px solid #ced4df; */
    // outline-color: #00bce1;
    padding: 10px;
    border: none;
    outline: none;
    width: 100%;
}

// .searchIconContainer {
//     position: absolute;
//     // top: 50%;
//     left: 130px;
//     // transform: translateY(-50%);
//     z-index: 2;
// }

// .searchInput {
//     padding: 10px 10px 10px 50px;

//     border-right: none;
//     outline: none;
//     width: 100%;
//     position: relative;
// }

// .searchIconContainer {
//     position: absolute;
//     left: 13%;
//     z-index: 2;
// }
.companyLi {
    display: flex;
    align-items: center;
    gap: 10px;
}

.selectedCompany {
    display: flex;
    align-items: center;
    gap: 10px;
}

.selectedCompany > img {
    height: 25px;
    width: 25px;
}

.selectedOption {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

.selectOtherCompany {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #919ba4;
}

.companyName {
    width: 100%;
}

.companyLi > img {
    height: 20px;
    width: 20px;
}

.searchIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background-color: transparent;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
    width: 230px;
}

.sidebarCon {
    width: 250px;
}

.dropdownHeader {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 47px;
}

.dropdownArrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
}

.dropdownArrow.open {
    transform: translateY(-50%) rotate(180deg);
}

.dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin-top: 11px;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1;
}

.dropdownOptions li {
    padding: 10px;
    cursor: pointer;
}

.dropdownOptions li:hover {
    background-color: #f1f1f1;
}

.profilePictureContainer {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profilePicture {
    width: 39px;
    height: 39px;
    border-radius: 50%;
}

.profilePicture > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

// .dropdown {
//     position: relative;
//     display: inline-block;

// }

.dropdownMenu {
    cursor: pointer;
    height: 100%;
}

.dropdownMenus {
    position: absolute;
    top: 100%;

    right: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ced4df;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));
    z-index: 1;
    width: 200px;
}

.dropdownMenus li {
    cursor: pointer;
}

.dropdownMenus li:hover {
    background-color: #f0f3f9;
}

.menuProperty {
    display: flex;
    padding: 10px;
    gap: 5px;
    align-items: center;
}

.menuProperty > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.hamburger {
    cursor: pointer;
}

.mobileNavContainer {
    display: none;
}

@media (max-width: 991px) {
    .sidebarMenu {
        /* Other styles... */
        margin-top: 70px;
        position: absolute;
        top: 0;
        right: -250px; /* Start position outside the viewport */
        width: 250px;
        height: 100vh;
        background-color: #fff;
        transition: right 0.5s ease-in-out;
    }

    .sidebarMenu.open {
        right: 0; /* Slide in from the right */
    }

    .menuItem1 {
        padding: 10px;
        width: 100%;
        // margin: 15px 0;
        margin-top: 15px;
        display: flex;
        // border-bottom: 1px dotted #ced4df;
        align-items: center;
        cursor: pointer;
        // border-bottom: 1px dotted #ced4df;
        // transition: background-color 0.3s ease;
    }
    .sideNav {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px;
        width: 100%;
        // border-bottom: 1px dotted #ced4df;
    }

    .dropdownText {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        color: #000;
    }

    .sidebarDropdownOptions {
        // position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0;
        margin-left: 7px;
        margin-top: 5px;
        list-style: none;
        background-color: #fff;
        padding-left: 10px;
        border-left: 2px solid #ced4df;
        z-index: 1;
    }

    .sidebarDropdownOptions li {
        padding: 10px;
        cursor: pointer;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        color: #000000;
    }

    .sidebarDropdownOptions li:hover {
        background-color: #f1f1f1;
    }

    .dropdownTextActive {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        color: #df508b;
    }

    .unactiveRoute {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    .activeRoute {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #df508b;
    }

    .menuItem {
        padding: 10px;
        width: 100%;
        cursor: pointer;
        // margin: 15px 0;
        align-items: center;
        display: flex;
        // border-bottom: 1px dotted #ced4df;
        // transition: background-color 0.3s ease;
    }

    .sideNavDropDownInner {
        display: flex;
        padding-right: 5px;
    }

    .dropdownText {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        color: #000;
    }
    .menuItemDropDown {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .dropdownInnerHeader {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .navbarContainer {
        display: none;
    }

    .mobileNavContainer {
        height: 70px;
        background: #fff;
        // padding-left: 20px;
        padding-right: 20px;
        border-top: 1px solid #ced4df;
        border-bottom: 1px solid #ced4df;
        border-right: 1px solid #ced4df;

        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 200;
        width: 100%;
        position: fixed;
        top: 0;
    }

    .logoContainerSmall {
        height: 70px;
        display: flex;
        width: 80px;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #ced4df;
        border-bottom: 1px solid #ced4df;
        border-right: 1px solid #ced4df;
    }

    .rightMobileNav {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .dropdown {
        width: 210px;
    }
}

@media (max-width: 480px) {
    .selectedCompany > img {
        display: none;
    }

    .dropdown {
        width: 172px;
    }

    .mobileNavContainer {
        padding-right: 10px;
    }
}
