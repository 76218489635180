.registerCompany {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.innerFieldContainer1,
.innerFieldContainer1_,
.innerFieldContainer1Adjust {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.fieldContainer2,
.fieldContainer3,
.fieldContainer3_,
.fieldContainer3Adjust {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer2 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.defaultImageContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.uploadContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
}

.uploadInputContainer {
    flex-grow: 1;
}

.belowUpload {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.imageContainer {
    width: 80px;
    height: 80px;
}

.imageContainer > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.fieldContainer4,
.fieldContainer4_ {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 30px;
}

.innerFieldContainer4,
.innerFieldContainer4_ {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContainer5 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer6 {
    display: flex;
    // justify-content: space-between;
    margin-top: 30px;
    flex-direction: column;
    gap: 30px;
}

.innerFieldContainer5,
.innerFieldContainer6 {
    width: 45%;
}

.radioButton5 {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.radioButton6 {
    width: 55%;
}

.radioButton5Adjust {
    width: 100%;
}

.fieldContainer7 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer7 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.formHeaders {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.fieldContainer8,
.fieldContainer9,
.fieldContainer10 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer8 {
    width: 22%;
    display: flex;
    flex-direction: column;
}

.innerFieldContainer9 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.innerFieldContainer10 {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.fieldContainer12 {
    margin-top: 30px;
}

.innerFieldContaine12 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.fieldContainer13 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer13Adjust {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 30px;
}

.innerFieldContainer13 {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.innerFieldContainer13Adjust {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.fieldContainer14 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer14 {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.fieldContainer15 {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.innerFieldContaine15 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.checkboxAndImgContainer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.fieldContainer16 {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.companyStatus {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: capitalize;
}

.iconContainer {
    display: flex;
    gap: 5px;
}

.tableContainer {
    margin-top: 30px;
}

.searchInputContainer {
    margin-top: 20px;
}

.icon {
    cursor: pointer;
}

.paginationContainer {
    height: 50px;
    display: flex;
    align-items: center;
}

@media (max-width: 1400px) {
    .tableContainer {
        overflow-y: scroll;
    }
    .mainTable {
        width: 1600px;
    }
}

@media (max-width: 1219px) {
    .fieldContainer3_,
    .fieldContainer10 {
        flex-wrap: wrap;
        gap: 30px;
    }

    .innerFieldContainer1_,
    .innerFieldContainer10 {
        width: 45%;
    }
}

@media (max-width: 1180px) {
    .fieldContainer4_,
    .fieldContainer14 {
        flex-wrap: wrap;
        gap: 30px;
    }

    .innerFieldContainer4_,
    .innerFieldContainer14 {
        width: 50%;
    }
}

@media (max-width: 1130px) {
    .fieldContainer3Adjust {
        flex-wrap: wrap;
        gap: 30px;
    }

    .innerFieldContainer1Adjust {
        width: 45%;
    }
    .radioButton6 {
        width: 100%;
    }
}

@media (max-width: 835px) {
    .fieldContainer1,
    .fieldContainer2,
    .fieldContainer3,
    .fieldContainer3_,
    .fieldContainer3Adjust,
    .fieldContainer4_ {
        flex-direction: column;
        gap: 30px;
    }

    .innerFieldContainer1,
    .innerFieldContainer2,
    .innerFieldContainer1_,
    .innerFieldContainer1Adjust,
    .innerFieldContainer4_ {
        width: 100%;
    }
}

@media (max-width: 725px) {
    .fieldContainer4,
    .fieldContainer5,
    .fieldContainer7,
    .fieldContainer8,
    .fieldContainer10,
    .fieldContainer9,
    .fieldContainer13,
    .fieldContainer13Adjust,
    .fieldContainer14,
    .fieldContainer15 {
        flex-direction: column;
        gap: 30px;
    }

    .innerFieldContainer4,
    .innerFieldContainer5,
    .radioButton5,
    .innerFieldContainer6,
    .radioButton6,
    .innerFieldContainer7,
    .innerFieldContainer8,
    .innerFieldContainer10,
    .innerFieldContainer9,
    .innerFieldContaine12,
    .innerFieldContainer13,
    .innerFieldContainer13Adjust,
    .innerFieldContainer14,
    .innerFieldContaine15 {
        width: 100%;
    }
    .buttonContainer {
        justify-content: space-between;
    }
}
