.App {
  text-align: center;
}

input {
  height: 47px;
  background: #ffffff;
  border: 1px solid #ced4df;
  outline-color: var(--perx-blue, #28245f);
  padding: 10px;
}

/* input[type='radio'] {
  border: 1px solid #00bce1;
  background-color: #00bce1;
} */

button {
  outline: unset;
  border: none;
  background: var(--perx-blue, #28245f);
  height: 47px;
  width: 15%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  cursor: pointer;
  color: #ffffff;
}

textarea {
  outline-color: var(--perx-blue, #28245f);
  /* border: 2px solid #00bce1; */
  border-color: 1px solid var(--perx-blue, #28245f) !important;

  height: 100px;
  padding: 10px;
}

button:hover,
.resetButton {
  background-color: #ffffff;
  border: 2px solid var(--perx-blue, #28245f);
  color: var(--perx-blue, #28245f);
}

p,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #000000;
}

@font-face {
  font-family: 'inter';
  src: url('../src/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf')
    format('truetype');
  /* Add more src declarations for other font file formats if available */
  /* e.g., src: url('../fonts/CustomFont-Regular.woff2') format('woff2'); */
  font-weight: normal;
  font-style: normal;
}

::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #919ba4;
}

a:hover {
  border: none;
  text-decoration: none;
}

button {
  width: 160px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 200px !important;
}
