* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  letter-spacing: 0.2px;
  font-family: 'Inter';
  padding: 0;
  margin: 0;
}
