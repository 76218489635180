.logDetailsContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.logDetailsMainContainer {
    margin-top: 30px;
    border: 1px solid #ced4df;
    padding: 20px;
}

.logInputContainer {
    display: flex;
    justify-content: space-between;
}

.logInputInnerContainer {
    display: flex;
    width: 20%;
    flex-direction: column;
}

.logButtonContainer {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.submit {
    width: 45%;
}

.reset {
    width: 45%;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    width: 45%;
    color: #fff;
    border: 2px solid #28245f;
}

.allQueryLogContainer {
    border: 1px solid #ced4df;
    padding: 10px;
    margin-top: 30px;
}

.indicatorContainer {
    display: flex;
    justify-content: space-between;
}

.orange,
.blue,
.pink,
.red {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.orange {
    background: #ffbc00;
}

.blue {
    background: #00bce1;
}

.pink {
    background: #df508b;
}

.red {
    background: #ff0000;
}

.indicatorInnerContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.indicatorBox {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
}

.logText {
    display: flex;
    align-items: center;
    height: 30px;
}

.labelText {
    margin: 0px;
}

.pagination {
    height: 100px;
    display: flex;
    align-items: center;
}

.handleLogDetailsHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

@media (max-width: 1500px) {
    .TableContainer {
        overflow-y: scroll;
    }
}

@media (max-width: 1186px) {
    .logInputContainer {
        flex-direction: column;
        gap: 30px;
    }
    .logInputInnerContainer {
        width: 100%;
    }
}

@media (max-width: 671px) {
    // .indicatorInnerContainer {
    //     flex-direction: column;
    //     justify-content: unset;
    // }

    .indicatorContainer {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 454px) {
    .indicatorInnerContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

@media (max-width: 768px) {
    .logButtonContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0px;
    }
    .logBtn {
        width: 120px;
    }
}
