.singleCategoryPage {
    min-height: 100vh;
}

.singleCategoryContainer {
    margin-top: 30px;
    min-height: 800px;
    padding-bottom: 30px;
}

.singleCategoryHeader {
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 54px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -1.08px;
}

.itemsContainer {
    background-color: #fff;
    width: 80%;
    margin: 30px auto;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sortingContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-right: 30px;
    margin-top: 30px;
}

.sortingText {
    color: #222;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.3px;
}

.activeSorting {
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.3px;
    border-radius: 4px;
    border: 1px solid #000;
    background: #e6e6e6;
    width: 200px;
}

.unactiveSorting {
    width: 200px;
    color: #343434;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.3px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fff;
}

//////////////////// Template Cards Styles/////////////////////////
.templateCard {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #eee;
    width: 230px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transition: 0.5s ease-out;
    }
}

.itemName {
    color: var(--text-primary, #222);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
    margin-top: 10px;
}

.itemPoint {
    color: #9c9c9c;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.15px;
    margin-top: 10px;
}

.itemImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 220px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.btnContainer {
    margin-top: 10px;
    .quickBuyButton {
        color: #222;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
        border-radius: 4px;
        border: 2px solid #000;
        background: #f0bd2d;
        width: 100%;
    }
}
/////////////////////////////////////////////
.loadMoreBTNContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;

    .loadMoreBTNEmpty {
        color: #000;
        text-align: center;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
        border-radius: 4px;
        border: 1.5px solid #222;
        background-color: #fff;
    }

    .loadMoreBTNUnEmpty {
        color: #000;
        text-align: center;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
        border-radius: 4px;
        border: 1.5px solid #222;
        background-color: #f0bd2d;
    }
}

@media (max-width: 729px) {
    .sortingContainer {
        flex-direction: column;
        padding-right: 0px;
    }
}

@media (max-width: 588px) {
    .itemsContainer {
        justify-content: center;
    }
}
