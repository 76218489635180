.defaultImageContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.uploadContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
}

.uploadInputContainer {
    flex-grow: 1;
}

.imageContainer {
    width: 80px;
    height: 80px;
}

.imageContainer > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.fieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer1 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

@media (max-width: 1045px) {
    .tableContainer {
        overflow-y: scroll;
    }

    .mainTable {
        width: 1200px;
    }

    .infoText {
        width: 1000px;
    }
}

@media (max-width: 1045px) {
    .fieldContainer1,
    .fieldContainer2 {
        flex-direction: column;
        gap: 30px;
    }

    .innerFieldContainer1,
    .fieldContainer3 {
        width: 100%;
    }

    .buttonContainer {
        justify-content: space-between;
    }
}

@media (max-width: 400px) {
    .buttonContainer {
        flex-direction: column;
    }

    .addMoreBTN {
        width: 100%;
    }

    .submit,
    .reset {
        width: 100%;
    }
}
