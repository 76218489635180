.templateCart {
    min-height: 100vh;
    background: #f8f8f8;
}

.cartTemplateBody {
    min-height: 800px;
    padding-bottom: 30px;

    width: 70%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}

.itemContainer {
    overflow-y: scroll;
}

.innerItemContainer {
    height: 500px;
}

.leftCartBody {
    width: 60%;
}

.rightCartBody {
    width: 35%;
}

.cartText {
    padding: 10px;
    background: #f2f2f2;
    color: #222;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.2px;
}

.pickupContainer {
    margin-top: 20px;
    background-color: #fff;
}

.pickupText {
    padding: 10px;
    color: #000;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.18px;
}

.cardCartOutter {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cartCart {
    padding: 10px;
    display: flex;
    height: 200px;

    justify-content: space-between;
    border-top: 1px solid #eee;
}

.cardLeft {
    width: 92%;
    display: flex;
    gap: 10px;
}

.cardRight {
    width: 8%;
}

.cardImg {
    height: 100%;
    width: 150px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.itemName {
    color: var(--text-primary, #222);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
}

.itemPoint {
    color: #222;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.36px;
}

.cardDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    width: calc(100% - 150px);
}

.numberInput {
    width: 50px;
    outline: none;
}

.qty {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
        color: #424242;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

.innerRightContainer {
    background-color: #fff;
}

.rightTop {
    padding: 10px;
    color: #000;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.2px;
    border-bottom: 1px solid #d8d8d8;
}

.rightBottom {
    padding: 10px;
}

.subTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    color: #000;
    text-align: right;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.15px;
}

.subTotalContent {
    color: #666;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.13px;
    margin-top: 10px;
}

.checkoutBTNContainer {
    margin-top: 20px;
}

.checkoutBTN {
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.16px;
    border-radius: 4px;
    border: 2px solid #000;
    background: #f0bd2d;
    width: 100%;
}

.emptyCart {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.16px;
    border-radius: 4px;
}

@media (max-width: 949px) {
    .cartTemplateBody {
        flex-direction: column;
    }

    .leftCartBody {
        width: 100%;
    }

    .rightCartBody {
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width: 574px) {
    .cartTemplateBody {
        width: 90%;
    }

    .cardImg {
        width: 120px;
        object-fit: cover;
        object-position: center;
        img {
            height: unset;
        }
    }

    .cardDetails {
        width: calc(100% - 100px);
    }
}
