.Profile {
    // margin-top: 60px;
    margin-top: 0;
    //min-height: calc(100% - 60px);
    min-height: calc(100% - 0px);
    // padding: 1.5rem;
    margin-bottom: 100px;
}

.handleMemberQueryHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.balance {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-top: 20px;
}

.balanceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
}

.pointsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 22%;
}

.balanceInnerContainer {
    display: flex;
    flex-direction: column;
    h6 {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.mainContainer {
    //margin-top: 30px;
    margin-top: 0;
    padding: 20px;
    width: 100%;
    border: 1px solid #ced4df;
}

.profileNavOuterContainer {
    border-bottom: 1px solid #ced4df;
}
.profileNav {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.profileNav > div {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    height: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #000000;
}

.currentNav {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #df508b !important;
    border-bottom: 1px solid #df508b;
    cursor: pointer;
}
.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}
.profileTextContainer {
    margin-top: 30px;
}

.profileText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
}

.profilePictureContainer {
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profilePictureContainer > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}

.MemberStatus {
    border: 1px solid var(--shade-gray-2, #ced4df);
    background: var(--perx-green, #81c041);

    cursor: not-allowed;
    input::placeholder {
        color: #fff;
    }
}

.profilePictureContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

.profileForm {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.inputContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.linkstyles {
    text-decoration: none;
    border-bottom: none;
}

.linkstyles:hover {
    text-decoration: none;
    border-bottom: none;
}

.inputNextContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.inputInnerContainer {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.inputInnerContainer > input {
    width: 100%;
}

.radioInputContainer {
    display: flex;
    gap: 30px;
}

.radioInputInnerContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.radioInputContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.selectedInfo {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.selectedInfoAdjust {
    width: 45%;

    display: flex;
    flex-direction: column;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: var(--perx-blue, #28245f);
    border: 2px solid var(--perx-blue, #28245f);
}

.reset:hover {
    background-color: var(--perx-blue, #28245f);
    color: #fff;
    border: 2px solid var(--perx-blue, #28245f);
}

.pointsRecieved {
    margin-top: 30px;
    width: 100%;
    padding: 20px;

    border: 1px solid #ced4df;
}

.tableContainer {
    width: 100%;
}

@media (max-width: 1097px) {
    .profileNavOuterContainer {
        overflow-y: scroll;
    }

    .profileNav {
        min-width: 1200px;
    }
}

@media (max-width: 1269px) {
    .pointsContainer {
        width: 250px;
        margin-top: 20px;
    }
}

@media (max-width: 670px) {
    .inputContainer,
    .inputNextContainer {
        flex-direction: column;
        width: 100%;
        gap: 30px;
    }
    .inputInnerContainer {
        width: 100%;
    }

    .selectedInfo {
        width: 100%;
    }

    .selectedInfoAdjust {
        padding-left: 0%;
    }

    .btnContainer {
        display: flex;
        justify-content: space-between;
    }
    .resetAndSubmitBTN {
        width: 45%;
    }
}

// @media (max-width: 533px) {
//     .pointsContainer {
//         width: 45%;
//         margin-top: 20px;
//     }
// }

// @media (max-width: 481px) {
//     .pointsContainer {
//         width: 200px;
//     }
// }

@media (max-width: 1080px) {
    .tableContainer {
        overflow-y: scroll;
    }
}
