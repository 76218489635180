.loginContainer {
    display: flex;
    flex-direction: row;
    height: 100dvh;
}
.leftContainer {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rightContainer {
    background-image: url("../assets/images/login-image.png");
    width: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 100%;
}

.rightContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.loginForm {
    padding: 20px;
    width: 100%;
    background: #ffffff;

    border: 1px solid #ced4df;
}

.formTop {
    height: 50px;
    margin-bottom: 20px;
}

.formTopLogo {
    height: 100px;
    width: 60%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formTopLogo > img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
}

.form1Container,
.form2Container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.formHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 17px;

    color: #000000;
}

.formParag {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 15px;
    color: #000000;
    margin-top: 10px;
    span {
        color: var(--warning-error, #f00);
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

// .dropdownButton {
//     background: #ffffff;
//     height: 47px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     border: 1px solid #ced4df;
//     padding: 10px;
// }

// .dropdownContent {
//     display: flex;
//     align-items: center;
// }

// .dropdownOptions {
//     background-color: white;
//     position: absolute;
//     min-width: 150px;
//     z-index: 3;
//     padding: 10px;
// }

// .dropdownOption {
//     height: 30px;
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 17px;
//     padding: 10px;
//     color: #000000;
// }

// .dropdownOption:hover {
//     background-color: #f0f3f9;
// }

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}
.dropdownPlaceholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #919ba4;
    margin-bottom: 0px;
}

.belowForm {
    margin-top: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.belowFormText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    color: #000000;
}

.dropdownHeader {
    padding: 10px;
    border: 1px solid #ced4df;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdownArrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
}

.dropdownArrow.open {
    transform: translateY(-50%) rotate(180deg);
}

.dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #f0f3f9;
    z-index: 3;
}

.dropdownOptions li {
    padding: 10px;
    cursor: pointer;
}

.dropdownOptions li:hover {
    background-color: #f0f3f9;
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.movingBorder {
    position: relative;
    background: #111;
    padding: 4px;
    width: 60%;
}
.movingBorder::before,
.movingBorder::after {
    content: "";
    position: absolute;
    inset: -0.2rem;
    z-index: -1;
    background: linear-gradient(var(--angle), #27d90a, #df508b, #00bce1);
    animation: rotate 5s linear infinite;
}
.movingBorder::after {
    filter: blur(10px);
}
@keyframes rotate {
    0% {
        --angle: 0deg;
    }
    100% {
        --angle: 360deg;
    }
}

.loginBtn {
    height: 47px;
    width: 100%;
    background: var(--perx-blue, #28245f);
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    text-align: center;
    border: none;
    color: #ffffff;
    margin-top: 20px;
}

.loginTextRHS {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.45px;
    text-transform: capitalize;
    margin-top: 50px;
    padding: 20px;
}

.passwordLink {
    text-decoration: none;
}

// CAROUSEL STYLING START

.BottomCarouselContainer {
    margin-top: 20px;
}
.marquee {
    display: flex;
    gap: 10px;
}
.carouselContainer {
    padding: 10px;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 60px;
    width: 220px;
    border-radius: 15px;
    border: 0.724px solid var(--Light-Gray, #e2e2e2);
    background: var(--White, #fff);
    box-shadow: 10.85447px 18.09078px 17.36715px 0px rgba(0, 0, 0, 0.05);
    p {
        color: var(--Gray, #858585);
        font-family: "Inter";
        font-size: 11.578px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
// CAROUSEL STYLING END

@media (max-width: 480px) {
    .rightContainer {
        display: none;
    }
    .leftContainer {
        width: 100%;
    }
    .movingBorder {
        width: 90%;
    }

    .formTopLogo {
        width: 90%;
    }
    .loginForm {
        width: 100%;
    }
}

@media (min-width: 481px) and (max-width: 769px) {
    .movingBorder {
        width: 80%;
    }

    .formTopLogo {
        width: 80%;
    }
}

@media (max-width: 905px) {
    .form1Container {
        padding-top: 10px;
    }
}

@media (max-width: 991px) {
    .loginTextRHS {
        color: #fff;
        text-align: center;
        font-family: "Inter";
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.45px;
        text-transform: capitalize;
        margin-top: 30px;
        padding: 20px;
    }

    @media (max-width: 785px) {
        .loginTextRHS {
            color: #fff;
            text-align: center;
            font-family: "Inter";
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            letter-spacing: -0.45px;
            text-transform: capitalize;
            margin-top: 40px;
            padding: 10px;
        }

        .TopCarouselContainer {
            margin-top: 30px;
        }
    }
}

@media (min-width: 1200px) {
    .loginTextRHS {
        color: #fff;
        text-align: center;
        font-family: "Inter";
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.45px;
        text-transform: capitalize;
        margin-top: 50px;
        padding: 20px;
        min-height: 30vh;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}
