.addFamilyMembersAtBranchContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldInputContainer1 {
    display: flex;
    justify-content: space-between;
}

.fieldInputContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldInputInnerContainer1 {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.fieldInputInnerContainer2 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    margin-top: 30px;
}

@media (max-width: 481px) {
    .enter {
        width: 100%;
    }

    .headerText {
        text-align: center;
    }
}

@media (max-width: 683px) {
    .fieldInputContainer1,
    .fieldInputContainer2 {
        flex-direction: column;
        gap: 30px;
    }
    .fieldInputInnerContainer1,
    .fieldInputInnerContainer2 {
        width: 100%;
    }
    .tableMainContainer {
        overflow-y: scroll;
        .HMQTable {
            width: 700px;
        }
    }
}
