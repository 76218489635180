.orderReceiptContainer {
    min-height: 100vh;
    background: #f8f8f8;
}
.orderReceiptContent {
    min-height: 800px;
    padding-bottom: 30px;

    width: 70%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}

// .itemContainer {
//     overflow-y: scroll;
// }

// .innerItemContainer {
//     height: 500px;
// }

.leftContent {
    width: 60%;
    background-color: #fff;
}

.rightContent {
    width: 35%;
    height: 700px;
}

.topLeftContent {
    padding: 10px;
    border-bottom: 2px solid #eee;
}

.appreciationText {
    color: #222;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.2px;
}

.emailText {
    color: #666;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
    margin-top: 10px;
}

.receiptDownload {
    color: #666;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
    margin-top: 10px;

    a {
        color: #0047ff;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.15px;
        text-decoration-line: underline;
    }
}

.orderNumber {
    color: var(--text-primary, #222);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
    margin-top: 10px;
}

.receiptDetail {
    margin-top: 10px;

    p {
        color: var(--text-primary, #222);
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.15px;
        span {
            color: var(--text-primary, #222);
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }
}

.rightContent {
    background-image: url("../assets/images/leafBG.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .advert {
        width: 80%;
        text-align: center;
        padding: 10px;
        h2 {
            color: #fff;
            text-align: center;
            font-family: "Inter";
            font-size: 42px;
            font-style: italic;
            font-weight: 800;
            line-height: 120%;
            letter-spacing: -0.42px;
            text-transform: uppercase;
        }
        p {
            color: #fff;
            text-align: center;
            font-family: "Inter";
            font-size: 20px;
            font-style: italic;
            font-weight: 800;
            line-height: 120%;
            letter-spacing: -0.2px;
            text-transform: uppercase;
            margin-top: 10px;
        }

        a {
            margin-top: 20px;
            color: #fff;
            text-align: center;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.2px;
            text-decoration-line: underline;
        }
    }
}

.itemsNumber {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #000;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.3px;
}

.cartText {
    padding: 10px;
    background: #f2f2f2;
    color: #222;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.2px;
}

.pickupContainer {
    margin-top: 20px;
    background-color: #fff;
}

.pickupText {
    padding: 10px;
    color: #000;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.18px;
}

.cardCartOutter {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cartCart {
    padding: 10px;
    display: flex;
    height: 200px;

    justify-content: space-between;
    border-top: 1px solid #eee;
}

.cardLeft {
    width: 100%;
    display: flex;
    gap: 10px;
}

.cardImg {
    height: 100%;
    width: 150px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.itemName {
    color: var(--text-primary, #222);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
}

.itemPoint {
    color: #222;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.36px;
}

.cardDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    width: calc(100% - 150px);
}

.numberInput {
    width: 50px;
    outline: none;
}

.qty {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
        color: #424242;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

.policyContainer {
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    margin-top: 30px;
    padding: 10px;
    .myAccount {
        margin-top: 20px;
    }
    a {
        color: #222;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.15px;
        text-decoration-line: underline;
    }

    .shoppingBTNContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .shoppingBTN {
            color: #000;
            text-align: center;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
            border-radius: 4px;
            border: 2px solid var(--btn-stroke, #222);
            background: #f0bd2d;
            width: 200px;
        }
    }
}

.appreciationTextContainer {
    margin: 30px auto;
    height: 250px;
    background-color: #fff;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #003b65;
    font-family: "Inter";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -2.25px;
}

@media (max-width: 1130px) {
    .rightContent {
        width: 300px;
        .advert {
            width: 90%;
        }
    }
}

@media (max-width: 949px) {
    .orderReceiptContent {
        flex-direction: column;
        width: 90%;
    }

    .appreciationTextContainer {
        width: 90%;
        text-align: center;
    }

    .leftContent {
        width: 100%;
    }

    .rightContent {
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width: 574px) {
    .cartTemplateBody {
        width: 90%;
    }

    .cardImg {
        width: 120px;
        object-fit: cover;
        object-position: center;
        img {
            height: unset;
        }
    }

    .cardDetails {
        width: calc(100% - 100px);
    }
}
