.securityQuestionContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.securityQuestionInnerContainer {
    width: 40%;
}

.perxLogo {
    width: 200px;
    height: 100px;
    margin: 0 auto;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.formContainer {
    border: 1px solid var(--shade-gray-2, #ced4df);
    background: var(--shade-white, #fff);
    padding: 20px;

    h6 {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    .securityQuestionInfo {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 30px;
    }

    .formMainContainer {
        display: flex;
        flex-direction: column;
    }
    .formMainContainer1 {
        display: flex;
        flex-direction: column;
        margin: 20px 0px;
    }
    .formMainContaine2 {
        display: flex;
        flex-direction: column;
    }
    .sendBTN {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 628px) {
    .securityQuestionInnerContainer {
        width: 90%;
    }
}
