.queryInputContainer {
    margin-top: 30px;
}

.mainContainer {
    //margin-top: 30px;
    margin-top: 0;
    padding: 20px;
    width: 100%;
    border: 1px solid #ced4df;
}

.queryInputContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.queryInputInnerContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;
}

.queryInputBox {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.queryBtn {
    width: 15%;
}

.reset {
    width: 15%;
    background-color: #fff;
    color: var(--perx-blue, #28245f);
    border: 2px solid var(--perx-blue, #28245f);
}

.reset:hover {
    background-color: var(--perx-blue, #28245f);
    color: #fff;
    border: 2px solid var(--perx-blue, #28245f);
}

.queryMainContainer {
    border: 1px solid #ced4df;
    padding: 10px;
    margin-top: 30px;
}

.paginationContainer {
    height: 100px;
    display: flex;
    align-items: center;
}

.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

@media (max-width: 1080px) {
    .tableContainer {
        overflow-y: scroll;
    }
}

@media (max-width: 653px) {
    .queryInputInnerContainer {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
    }

    .queryInputBox,
    .queryBtn,
    .reset {
        width: 100%;
    }
}
