.countryCurrencyContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.inputFieldContainer1 {
    display: flex;
    justify-content: space-between;
}
.inputFieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.inputFieldInnerContainer1 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.inputFieldInnerContainer2 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.searchInputContainer {
    display: flex;
    gap: 30px;
}

.searchInputBtn {
    width: 150px;
}

.paginationContainer {
    height: 50px;
    display: flex;
    align-items: center;
}

.tableContainer {
    margin-top: 30px;
}

.icon {
    cursor: pointer;
}

@media (max-width: 694px) {
    .tableContainer {
        overflow-y: scroll;
    }

    .mainTable {
        width: 700px;
    }
}
@media (max-width: 633px) {
    .inputFieldContainer1 {
        flex-direction: column;
        gap: 30px;
    }

    .inputFieldInnerContainer1 {
        width: 100%;
    }
}

@media (max-width: 490px) {
    .buttonContainer {
        justify-content: space-between;
    }
}

@media (max-width: 430px) {
    .inputFieldContainer2 {
        flex-direction: column;
        gap: 30px;
    }

    .inputFieldInnerContainer2 {
        width: 100%;
    }

    .searchInputContainer {
        flex-direction: column;
        gap: 30px;
    }

    .searchInputBtn {
        width: 100%;
    }
}
