.lslPointsContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;

    margin-top: 30px;
}

.downloadBtnContainer {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.downloadBtnPDF {
    background-color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #df508b;
    border: 2px solid #df508b;
}

.downloadBtnXLSX {
    background-color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #81c041;
    border: 2px solid #81c041;
}

@media (max-width: 674px) {
    .tableContainer1 {
        overflow-y: scroll;
    }
    .tableInnerContainer1 {
        width: 700px;
    }
}
