.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdownToggle {
    padding: 8px 16px;
    background-color: #fff;
    color: #000;
    height: 47px;
    border: 1px solid #ced4df;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdownToggleDisabled {
    padding: 8px 16px;
    background-color: #f0f3f9;
    color: #000;
    height: 47px;
    border: 1px solid #ced4df;
    border-radius: 4px;
    cursor: not-allowed;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
    z-index: 10;
}

.dropdownItem {
    padding: 8px 16px;
    color: #333;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.dropdownItem:last-child {
    border-bottom: none;
}

.dropdownItem:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.dropdownInitialText {
    color: var(--shade-gray-1, #919ba4);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
