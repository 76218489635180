.redeemCatalogueContainer {
    // margin-top: 60px;
    margin-top: 0;
    // min-height: calc(100% - 60px);
    min-height: calc(100% - 0px);
    // padding: 1.5rem;
    margin-bottom: 100px;
}

.redeemCatalogueMainContainer {
    // margin-top: 30px;
    margin-top: 0;
    padding: 20px;
    width: 100%;
    border: 1px solid #ced4df;
}

.redeemTextContainer {
    margin-top: 20px;
}

.redeemTextContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

.searchField {
    display: flex;
    align-items: center;

    // width: 80%;
    border: 1px solid #ced4df;
    height: 47px;
    flex-grow: 1;
    padding-left: 10px;
}

.searchInput {
    height: 45px;
    background: #ffffff;
    /* border: 1px solid #ced4df; */
    // outline-color: #00bce1;
    padding: 10px;
    border: none;
    outline: none;
    width: 100%;
}

.cartContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 47px;
    gap: 50px;
}

.cartIconContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding-right: 10px;
    background: #81c041;
}

.cartIcon {
    height: 47px;
    width: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.cartNum {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
}

.dropdownContainer {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.dropdownInnerContainer {
    width: 30%;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // gap: 5%;

    justify-content: space-between;
    margin-top: 30px;
}

.toCardBtn {
    width: 30%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    // background: #00bce1;
    color: #ffffff;
    margin-top: 50px;
}

.paginationContainer {
    height: 100px;
    display: flex;
    align-items: center;
}

.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

// toCardBtn

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.proceedBTN {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    border-bottom: none;
    width: 100%;
}

.proceedBTN:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #00bce1;
    text-decoration: none;
    border-bottom: none;
}

@media (max-width: 481px) {
    .cartContainer {
        gap: 20px;
    }
    .toCardBtn {
        width: 100%;
    }
    .dropdownContainer {
        flex-direction: column;
        gap: 30px;
    }
    .dropdownInnerContainer {
        width: 100%;
    }
}

@media (min-width: 482px) and (max-width: 760px) {
    .dropdownContainer {
        flex-direction: column;
        gap: 30px;
    }

    .dropdownInnerContainer {
        width: 100%;
    }
    .toCardBtn {
        width: 200px;
    }
}
@media (max-width: 645px) {
    .cardContainer {
        justify-content: center;
    }
}

@media (min-width: 645px) and (max-width: 1173px) {
    .cardContainer {
        justify-content: space-between;
    }
}
