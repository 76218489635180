.lslInvoiceContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;

    margin-top: 30px;
}

.inputInnerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.inputInnerContainerMiddle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    margin-bottom: 30px;
}

.inputAndLabelContainer {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.paidAndInvoiceContainer {
    width: 35%;
}

.noteText {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #81c041;
}

.noData {
    display: flex;
    justify-content: center;
    width: 100%;
}

.submitBTN {
    width: 180px;
}

@media (max-width: 808px) {
    .invoiceTable {
        width: 800px;
    }

    .tableContainer {
        overflow-y: scroll;
    }
}

@media (max-width: 699px) {
    .inputInnerContainer {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
    }

    .inputInnerContainerMiddle {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
    }
    .inputAndLabelContainer {
        width: 100%;
    }
    .paidAndInvoiceContainer {
        width: 100%;
    }
    .submitBTN {
        width: 100%;
    }
}
