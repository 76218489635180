.menuMap {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.tableContainer {
    padding: 10px;
    border: 1px solid #ced4df;
    margin-top: 30px;
    overflow-y: scroll;
}

.checkboxTableData {
    background: #f0f3f9;
    width: 200px;
}

.tableHead {
    background: #f0f3f9;
    width: 250px;
}

.menuMapTable {
    width: 1200px;
}
