.transactionTypeContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;

    margin-top: 30px;
}

.transactionTypeLabel {
    margin-top: 20px;
}

.formContainer {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.transactionTypeInput {
    flex-grow: 1;
}

.transactionTypeBtn {
    width: 200px;
}

.transactionTypeNoteText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: right;

    color: #81c041;
    margin-top: 15px;
}

.tableContainer {
    margin-top: 30px;
    border: 1px solid #e6e6e6;
    padding: 10px;
}

.paginationContainer {
    height: 100px;
    display: flex;
    align-items: center;
}

.successModal {
    width: 330px;
    height: 80px;
    background-color: #5ed700;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 80px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
}

.successText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.allCheckboxContainer {
    margin-top: 20px;
}

.generalCheckboxContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    // width: 120px;
}

.checkboxText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

// SEARCH BAR STYLING

.searchInput {
    flex-grow: 1;
}

.masterMenuInput {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.menuSections {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.menuInnerSections {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.viewMapBTN {
    width: 190px;
    background: #df508b;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.viewMapBTN:hover {
    width: 190px;
    background: #fff;
    border: 2px solid #df508b !important;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #df508b;
}

.viewMapBTNContainer {
    margin-top: 30px;
}

.submitAndResetBTN {
    margin-top: 30px;
    display: flex;
    gap: 50px;
}

.resetBTN {
    background-color: #fff;
    border: 2px solid #00bce1;
    color: #00bce1;
}

.resetBTN:hover {
    background-color: #00bce1;
    border: 2px solid #00bce1;
    color: #fff;
}

.submitAndResetBTN > button {
    width: 190px;
}
.noteTextContainer {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.noteText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #81c041;
}

.icon {
    cursor: pointer;
}

.defaultImageContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.uploadContainer {
    margin-top: 30px;
}

.imageContainer {
    width: 80px;
    height: 80px;
}

.imageContainer > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.noteText {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #81c041;
}

.buttonAndUplaodContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
}

.submitImageBTN {
    width: 150px;
}

.submitImageBTN > button {
    width: 100%;
}

.uploadInputContainer {
    flex-grow: 1;
}

@media (max-width: 669px) {
    .checkboxContainer {
        margin-top: 20px;
    }
}

@media (max-width: 1000px) {
    .tableContainer {
        overflow-y: scroll;
    }

    .dataTable {
        width: 800px;
    }
}

@media (max-width: 528px) {
    .noteText {
        // justify-content: flex-start;
        text-align: inherit;
        font-size: 11px;
    }

    .masterMenuInput {
        width: 100%;
    }

    .menuInnerSections {
        width: 100%;
    }

    .menuSections {
        gap: 20px;
    }

    .formContainer,
    .menuSections {
        flex-direction: column;
    }
    .transactionTypeBtn {
        width: 100%;
    }

    .transactionTypeNoteText {
        margin-top: 10px;
        text-align: left;
    }

    .successModal {
        margin-right: 10px;
    }

    .transactionTypeLabel {
        margin-top: 10px;
    }
}

@media (max-width: 584px) {
    .buttonAndUplaodContainer {
        flex-direction: column;
        align-items: unset;
    }
    .submitImageBTN {
        width: 100%;
    }
}
