.viewOrEditPartnerUser {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer {
    p {
        color: var(--shade-black, #000);

        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .field1 {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 10px;
    }
}

@media (max-width: 425px) {
    .headerText {
        text-align: center;
    }
}
