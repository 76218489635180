.navContainer {
    height: 100px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // overflow-y: hidden;
    padding: 0% 5%;
}

.navLogo {
    height: 60px;
    width: 100px;
    display: flex;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.searchContainer {
    display: flex;
    align-items: center;
    padding-left: 5px;
    border-radius: 24px;
    border: 1px solid #ccc;
    background: #fff;
    width: 350px;
}

.navSearch {
    outline: none;
    border: none;
    width: 90%;
    // padding-left: 10px;
    height: 40px;
}

.searchIconContainer {
    border-right: 1px solid #ccc;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
}

.memberIcon {
    display: flex;
    gap: 5px;
    p {
        color: #000;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

.balanceContainer {
    min-width: 100px;
    p {
        color: var(--text-primary, #222);
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.15px;
    }

    h6 {
        color: var(--text-primary, #222);
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

///////////////// Dropdown/////////////////////
/* Dropdown container */
.dropdown {
    position: relative;
}

/* Dropdown title */
.dropdownTitle {
    cursor: pointer;
    padding: 10px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 4px;

    .username {
        color: #000;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

/* Dropdown options */
.dropdownOptions {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    li {
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}

.templateHmaburgerContainer {
    display: none;
}

@media (max-width: 980px) {
    .memberIconContainer,
    .balanceOutterContainer,
    .dropDownContainer {
        display: none;
    }

    .templateHmaburgerContainer {
        display: block;
    }

    .searchOutterContainer {
        width: 70%;

        .searchContainer {
            width: 100%;
        }
    }

    .mobileNav {
        position: fixed;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        z-index: 1000;
    }

    .mobileNav.open {
        top: 100px;
        opacity: 1;
    }

    .navContent {
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        max-width: 80%;
        position: relative;
        // height: 80%;
    }

    .topContent {
        background: #eee;
        padding: 10px;
    }

    .topUsernameContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .usernameMobile {
        display: flex;
        align-items: center;
        gap: 5px;

        .username {
            color: #000;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }

    .mobileLogoutText {
        color: #000;
        text-align: right;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.3px;
        text-decoration-line: underline;
    }
    .mobileBalanceContainer {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        gap: 10px;
    }

    .mobileBalanceText {
        color: #003b65;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.3px;
    }

    .mobileBalanceNum {
        color: #003b65;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.3px;
    }

    .mobileNavContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
            padding: 10px;
        }
    }

    .mobileLine {
        width: 295px;

        border-bottom: 2px solid #eee;
    }

    .mobileProfile {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 100px;
    }

    .dropdownMobile {
        position: relative;
    }

    /* DropdownMobile title */
    .dropdownMobileTitle {
        cursor: pointer;

        color: #000;
        display: flex;
        align-items: center;
        gap: 5px;

        padding: 0px;
        display: flex;
        justify-content: space-between;
        .username {
            color: #000;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }

    /* DropdownMobile options */
    .dropdownMobileOptions {
        position: absolute;

        background-color: #fff;
        border: 1px solid #ccc;

        list-style-type: none;
        padding: 0;
        margin: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        right: unset;
        top: 30px;
        z-index: 2;
        width: 200px;
        li {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .dropdown1Mobile {
        position: relative;
    }

    /* DropdownMobile title */
    .dropdownMobile1Title {
        cursor: pointer;

        color: #000;
        display: flex;
        align-items: center;
        gap: 5px;

        padding: 0px;
        display: flex;
        justify-content: space-between;
        .username {
            color: #000;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }

    /* DropdownMobile options */
    .dropdownMobile1Options {
        position: absolute;
        background-color: #fff;
        border: 1px solid #ccc;

        list-style-type: none;
        padding: 0;
        margin: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        right: unset;
        top: 40%;
        z-index: 1;
        width: 200px;
        li {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .activeMobileNav {
        background: #ecf7fe;
    }
}

@media (max-width: 600px) {
    .searchOutterContainer {
        display: none;
    }
}
