/* Sidebar.scss */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

// .menuItemAdjust
// menuItemDropDown

.sidebarMenu {
    list-style: none;
    padding: 0;
    width: 100%;
}

.makeScroll {
    overflow-y: scroll;
    height: 80vh;
}

.makeInnerScroll {
    height: 90vh;
    padding-bottom: 100px;
}

.menuItemLogo {
    width: 100%;
}

.menuItem {
    padding: 10px;
    width: 100%;
    cursor: pointer;
    // margin: 15px 0;
    align-items: center;
    display: flex;
    border-bottom: 1px dotted #ced4df;
    // transition: background-color 0.3s ease;
}

.menuItemDefinedMaster {
    width: 100%;
    cursor: pointer;
    // margin: 15px 0;
    align-items: center;
    // padding: 0px;
    display: flex;
    border-bottom: 1px dotted #ced4df;
    // transition: background-color 0.3s ease;
}

.menuItem1 {
    padding: 10px;
    width: 100%;
    // margin: 15px 0;
    margin-top: 15px;
    display: flex;
    border-bottom: 1px dotted #ced4df;
    align-items: center;
    cursor: pointer;
    // border-bottom: 1px dotted #ced4df;
    // transition: background-color 0.3s ease;
}

.menuItemDropDown {
    display: flex;
    gap: 10px;
    align-items: center;
}

// .menuItem:hover {
//     background-color: #555;
// }

// .menuItem1:hover {
//     background-color: #555;
// }

.logoContainer {
    height: 70px;
    width: 100%;
    border-top: 1px solid #ced4df;
    border-bottom: 1px solid #ced4df;
    border-left: 1px solid #ced4df;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

// DROP DOWN START

.sidebarDropdown {
    position: relative;
    display: inline-block;
}

.menuItemAdjust {
    width: 100%;
}

// .dropdownContainer {
//     padding: 0px;
// }

.sideDropdownHeader {
    cursor: pointer;
    display: flex;
    width: 100%;
}

.sidebarDropdownOptions {
    // position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin-left: 7px;
    margin-top: 5px;
    list-style: none;
    background-color: #fff;
    padding-left: 10px;
    border-left: 2px solid #ced4df;
    z-index: 1;
}

.sidebarDropdownOptions li {
    padding: 10px;
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
}

.sidebarDropdownOptions li:hover {
    background-color: #f1f1f1;
}

.sidebarDropdownOptionsAdjust {
    // position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin-left: 7px;
    margin-top: 5px;
    list-style: none;
    background-color: #fff;
    // padding-left: 10px;
    border-left: 2px solid #ced4df;
    z-index: 1;
}

.sidebarDropdownOptionsAdjust li {
    // padding: 10px;
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
}

.sidebarDropdownOptionsAdjust li:hover {
    background-color: #f1f1f1;
}

.sideNav {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    width: 100%;
    // border-bottom: 1px dotted #ced4df;
}

.dropdownInnerHeader {
    display: flex;
    gap: 5px;
    align-items: center;
}

.sideNavDropDown {
    width: 100%;
}

.chevronContainer {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sideNavDropDownInner {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
}

.dropdownText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    color: #000;
}

.dropdownTextActive {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    color: #df508b;
}

.logoContainerSmall {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ced4df;
    border-bottom: 1px solid #ced4df;
    border-left: 1px solid #ced4df;
}

.sidebarMenuSmall {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 10px;
}

.sidebarSmall {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

.menuItemSmall {
    padding: 10px;
    cursor: pointer;
}

.unactiveRoute {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.activeRoute {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #df508b;
}

.invisible {
    display: none;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
}

.visible {
    display: block;
    position: absolute;
    width: 230px;
    z-index: 11;
    background-color: #fff;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

// .double {
//     font-size: 13px;
// }

.iconSettingsSmall {
    display: flex;
    gap: 20px;
}

.container {
    margin-left: 25px;
    border-radius: 10px;
    box-shadow: 5px 8px 5px -3px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 5px 8px 5px -3px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 5px 8px 5px -3px rgba(0, 0, 0, 0.23);
}

.innerContainer {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
}

.innerContainerDropdown {
    padding: 15px 0px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.innerli {
    margin-bottom: 10px;
}

.dropdownLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.dropdownLink:hover {
    border: none;
    text-decoration: none;
}

.innerContainerDropdown > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.singlelink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.singlelink:hover {
    text-decoration: none;
    color: #df508b;
}

.dropdownLink:hover {
    color: #df508b;
}
