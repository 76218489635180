.smartphoneHomepage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.smartphoneContentContainer {
    height: 60%;
}

// .navContent {
//     height: 300px;
//     background-color: purple;
//     position: absolute;
//     z-index: 10;
// }

.smartphoneNav {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

    .inputSearch {
        width: 80%;
    }
    .hamburger {
        cursor: pointer;
    }
    .inputSearch > input {
        width: 100%;
        border-radius: 24px;
        border: 1px solid #ccc;
        background: #fff;
        outline-color: white !important;
        padding-left: 15px;
    }
}

.cartNumberContainer {
    height: 70px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    p {
        color: #000;
        text-align: center;
        font-family: "Inter";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.48px;
    }
}

.smartphoneContentOutter {
    overflow-y: scroll;

    height: calc(100% - 130px);
}

.smartphoneContent {
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    .smartPhoneItemCard {
        width: 170px;
        padding: 10px;
        .smartPhoneCardImg {
            width: 100%;
            height: 150px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .buttonContainer {
            width: 100%;
            .addToCartBTN {
                width: 100%;
                border-radius: 67.791px;
                border: 2px solid #000;
                background: var(--Yellow, #f0bd2d);
                color: var(--shade-black, #000);
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .itemName {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 13.586px;
            font-style: normal;
            font-weight: 400;
            line-height: 140.625%;
            margin-top: 5px;
        }
        .itemPoint {
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 5px 0px;
            p {
                color: var(--shade-black, #000);
                font-family: "Inter";
                font-size: 13.586px;
                font-style: normal;
                font-weight: 700;
                line-height: 140.625%;
            }
            span {
                color: var(--Yellow, #f0bd2d);
                font-family: "Inter";
                font-size: 13.586px;
                font-style: normal;
                font-weight: 700;
                line-height: 140.625%;
            }
        }
    }
}

.navFooter {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

    .navInnerFooter {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        width: 20%;
        cursor: pointer;
        .unactive {
            color: var(--Black, #000);
            text-align: center;
            font-family: "Inter";
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
            margin-top: 5px;
            cursor: pointer;
        }

        .active {
            color: #f0bd2d;
            text-align: center;
            font-family: "Inter";
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
            margin-top: 5px;
            cursor: pointer;
        }
    }
}

.iPhone5c,
.SamsungGalaxyS5,
.Lumia920,
.Nexus5,
.iPhone4s,
.iPhone5s,
.HTCOne {
    display: flex;
    justify-content: center;

    .smartPhoneItemCard {
        width: 100%;
        padding: 10px;
        .smartPhoneCardImg {
            width: 100%;
            height: 150px;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}

// MODAL STYLING START
/* Modal Overlay Styles */
.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;

    z-index: 100;
}

/* Modal Styles */
.modal {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-width: 80%;
    width: 400px;
    padding: 10px;
    z-index: 101; /* Slightly higher than overlay */
}

/* Close Button Styles */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* Modal Content Styles */
.modalContent {
    /* Add your modal content styles here */
    height: 200px;

    .modalNav {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-top: 30px;
        li:first-child {
            background: #ebebec;
        }

        li {
            padding: 10px;
            color: #222;

            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.3px;
            cursor: pointer;
        }
    }
}

// CART STYLINGS
.cartCart {
    padding: 10px;
    display: flex;
    height: 200px;

    justify-content: space-between;
    border-top: 1px solid #eee;
}

.cardLeft {
    width: 92%;
    display: flex;
    gap: 10px;
}

.cardRight {
    width: 8%;
}

.cardImg {
    height: 100%;
    width: 120px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.itemName {
    color: var(--text-primary, #222);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
}

.itemPoint {
    color: #222;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.36px;
}

.cardDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    width: calc(100% - 150px);
}

.numberInput {
    width: 50px;
    outline: none;
}

.qty {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
        color: #424242;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

.innerRightContainer {
    background-color: #fff;
}

.emptyCart {
    height: 500px;
    display: flex;

    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.4px;
}

.confirmCheckoutBTNContainer {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    .confirmCheckoutBTN {
        color: #000;
        width: 80%;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.14px;
        border-radius: 200px;
        border: 2px solid #000;
        background: #f0bd2d;

        &:hover {
            border: 2px solid #000;
        }
    }
}

.userPointsContainer {
    overflow-x: scroll;
    display: flex;
    height: 100px;
    gap: 10px;
    margin-top: 10px;

    .currentPoint {
        min-width: 200px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        border-radius: 17.093px;
        background: var(--impromptu-orange-light, #fffaf2);
        p {
            color: var(--neutral-500, #64748b);
            font-family: "Inter";
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        h6 {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 13.674px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.273px;
            margin-top: 5px;
        }
    }

    .gainedPoint {
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 17.093px;
        background: var(--impromptu-blue-light, #f2f2ff);
        p {
            color: var(--neutral-500, #64748b);
            font-family: "Inter";
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        h6 {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 13.674px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.273px;
            margin-top: 5px;
        }
    }

    .redeemedPoint {
        display: flex;
        min-width: 200px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 17.093px;
        background: var(--impromptu-purple-light, #fcf3fc);
        p {
            color: var(--neutral-500, #64748b);
            font-family: "Inter";
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        h6 {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 13.674px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.273px;
            margin-top: 5px;
        }
    }
}

.profileIconsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    padding: 0px 10px;
    height: 400px;
}

.profileIcons {
    border-radius: 20px;
    border: 1px solid var(--neutral-200, #e2e8f0);
    background: var(--shade-white, #fff);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.32px;
    }
}
