.pdf, .pdf:hover{
    border:2px solid #DF508B;
    color:#DF508B;
    background: none;
    height: 47px;
    width: 165.5px;
    padding:15px;
    margin-right: 10px;
}

