.transactionTypeContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    height: 150px;
    margin-top: 30px;
}

.transactionTypeLabel {
    margin-top: 20px;
}

.formContainer {
    display: flex;
    gap: 20px;
    padding-top: 15px;
    justify-content: space-between;
    align-items: flex-end;
}

.transactionTypeInput {
    flex-grow: 1;
}

.transactionTypeBtn {
    width: 200px;
}

.transactionTypeNoteText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: right;

    color: #81c041;
    margin-top: 15px;
}

.tableContainer {
    margin-top: 30px;
    border: 1px solid #e6e6e6;
    padding: 10px;
}

.paginationContainer {
    height: 100px;
    display: flex;
    align-items: center;
}

.successModal {
    width: 300px;
    height: 80px;
    background-color: #5ed700;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 80px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
}

.successText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

// SEARCH BAR STYLING

.searchInput {
    flex-grow: 1;
}

// .searchInputContainer {
//     display: flex;
// }

@media (max-width: 1038px) {
    .inputContainer {
        height: 400px;
    }

    .formContainer {
        flex-direction: column;
        align-items: unset;
    }
}

@media (max-width: 665px) {
    .transactionTypeBtn {
        width: 100%;
    }
    .inputContainer {
        height: 420px;
    }

    .tableContainer {
        overflow-y: scroll;
    }

    .dataTable {
        width: 700px;
    }

    .transactionTypeNoteText {
        margin-top: 10px;
        text-align: left;
    }

    .successModal {
        margin-right: 10px;
    }

    .transactionTypeLabel {
        margin-top: 10px;
    }
}

@media (max-width: 481px) {
    .headerText {
        text-align: center;
    }
}
