.modalContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    background-color: #fff;

    padding: 20px;
    max-width: 300px;
    width: 100%;
    border-radius: 8px;
}

.modalHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    margin-top: 10px;
}

.belowHeader {
    margin-top: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.iconContainer {
    background-color: #d4ecfe;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: var(--perx-blue, #28245f);
    border: 2px solid var(--perx-blue, #28245f);
}

.reset:hover {
    background-color: var(--perx-blue, #28245f);
    color: #fff;
    border: 2px solid var(--perx-blue, #28245f);
}

@media (max-width: 401px) {
    .modalContent {
        background-color: #fff;
        padding: 20px;
        width: 80%;
    }
}
