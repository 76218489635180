.accountDashboardContainer {
    min-height: 100vh;
    background: #f8f8f8;
}

.accountDashboard {
    width: 70%;
    margin: 30px auto;
    min-height: 500px;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
}

.leftDashboard {
    width: 30%;
    background-color: #fff;
}

.rightDashboard {
    width: 65%;
    background-color: #fff;
    height: 600px;
}
.dashboardText {
    padding: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    color: #222;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.24px;
}

.accountDashboardScreen {
    padding: 10px;
    height: 100px;
}

.accountScreen {
    border-radius: 12px;
    background: #e6e6e6;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.accountInnerScreen {
    width: 33%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 3%;
    p {
        color: #000;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.14px;
    }

    h6 {
        color: #000;
        font-family: "Inter";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.24px;
    }
}

.defaultAddressContainer {
    margin-top: 30px;
    padding: 10px;
    h6 {
        color: #888;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.14px;
    }

    .defaultAddressInnerContainer {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
            color: var(--text-primary, #222);
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }
}

.optoutContainer {
    margin-top: 50px;
    padding: 10px;
    height: 130px;

    .optoutInnerContainer {
        background: #fedcdc;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        p {
            color: #a20606;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.14px;
        }
    }

    .optoutBTN {
        color: #fff;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        border-radius: 4px;
        background: #a20606;

        &:hover {
            border: none;
        }
    }
}

.leftTopContent {
    padding: 10px;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    h6 {
        color: var(--text-primary, #222);
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
    }

    p {
        color: var(--text-secondary, #6a6a6a);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.14px;
    }
}

.itemUl {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .eachItem {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
            color: var(--text-primary, #222);
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }

    .itemList {
        margin: 10px 0px;
        padding: 10px;
        cursor: pointer;
    }

    .itemList:first-child {
        background: #e1edf5;
        p {
            color: var(--text-primary, #222);
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
        }
    }
}

.mobileItemNav {
    display: none;
}

@media (max-width: 1249px) {
    .accountDashboardScreen {
        overflow-x: scroll;
        .accountScreen {
            width: 600px;
        }
    }
}

@media (max-width: 1097px) {
    .optoutBTN {
        margin-top: 5px;
    }
}

@media (max-width: 933px) {
    .leftDashboard {
        display: none;
    }

    .mobileItemNav {
        display: flex;
        padding: 10px 0px;
        background: #e5e8eb;
        width: 70%;
        margin: 0 auto;
        .mobileNavText {
            color: #003b65;
            text-align: center;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;

            width: 100%;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.3px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 24px;
        }
    }

    .rightDashboard {
        width: 100%;
    }
    .accountScreen {
        width: 700px;
    }
    .accountDashboard {
        margin-top: 0px;
    }
}

@media (max-width: 826px) {
    .accountDashboardScreen {
        overflow-x: scroll;
        .accountScreen {
            width: 600px;
        }
    }
}

@media (max-width: 628px) {
    .accountDashboard,
    .mobileItemNav {
        width: 90%;
    }
}
