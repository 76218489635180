.cartContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.cartMainContainer {
    margin-top: 30px;
    // padding: 20px;
    width: 100%;
    // border: 1px solid #ced4df;
}
