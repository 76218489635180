.AirtimeCheckoutContainer {
    min-height: 100vh;
    background: #f8f8f8;
}

.AirtimeCheckoutContent {
    min-height: 500px;
    width: 70%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 928px) {
        flex-direction: column;
        gap: 30px;
    }

    @media (max-width: 500px) {
        width: 90%;
    }
}

.AirtimeCheckoutLeft {
    width: 40%;
    .checkoutLogo {
        width: 100%;
        height: 250px;

        img {
            border-radius: 32.257px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    @media (max-width: 928px) {
        width: 100%;
    }
}

.AirtimeCheckoutRight {
    width: 55%;
    .innerRight {
        background-color: #fff;
        padding: 10px;
    }
    .hearderText {
        color: #222;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.2px;
    }

    .parag {
        margin: 20px 0px;
        color: #666;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.15px;
    }

    .userNumber {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .recipientText {
        color: #666;
        font-family: "Inter";
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.17px;
        text-transform: capitalize;
        margin: 20px 0px;
    }

    .totalContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        height: 60px;
        margin: 20px 0px;
        h5 {
            color: #333;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.2px;
        }
        h6 {
            color: var(--First-Bank-Blue, #003b65);

            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.2px;
        }
    }

    .processBTN {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .processOrderBTN {
            color: #fff;
            text-align: center;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.15px;
            border-radius: 4px;
            background: var(--Process-green, #03ac50);
            width: 250px;
            height: 47px;
            &:hover {
                border: none;
            }
        }
    }

    .checkoutText {
        padding: 10px;
        margin: 20px 0px 100px 0px;
        .checkoutText1 {
            color: #222;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.13px;
        }
        .checkoutText2 {
            color: #222;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.13px;
            margin-top: 20px;
        }
    }
    @media (max-width: 928px) {
        width: 100%;
    }
}
