.AirtimeAndBillsContainer {
    min-height: 100vh;
    background-color: #fff;
}

.AirtimeAndBillsContent {
    width: 70%;
    margin: 30px auto;
    min-height: 500px;
    padding-bottom: 100px;

    .checkoutLink {
        text-decoration: none;
    }

    .headerText {
        color: #000;
        text-align: center;
        font-family: "Inter";
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.54px;
        text-align: center;
    }
    .AirtimeAndBillsInnerContent {
        display: flex;
        justify-content: space-between;
        height: 100%;
        margin-top: 100px;

        .AirtimeAndBillsLeft {
            width: 20%;
            .AirtimeAndBillsBTN {
                color: #222;
                font-family: "Inter";
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.15px;
                border-radius: 20px;
                border: 2px solid #003b65;
                background: #cff7ff;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .BRTBTN {
            color: var(--text-primary, #222);
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.15px;
            border: 2px solid #003b65;
            border-radius: 20px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .AirtimeAndBillsRight {
            width: 75%;
            h6 {
                color: #000;
                font-family: "Inter";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.24px;
            }

            .brtContainer {
                margin-top: 30px;
                .brtText {
                    color: #000;
                    font-family: "Inter";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -0.24px;
                    margin-bottom: 20px;
                }
            }

            .airtimeCardContainer {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .airtimeContainer {
                    padding: 10px;
                    width: 140px;
                    height: 150px;
                    transition: box-shadow 0.3s ease;

                    cursor: pointer;
                    &:hover {
                        box-shadow: 0 4px 6px rgba(203, 200, 200, 0.2);
                    }

                    .airtel {
                        width: 100%;
                        height: 110px;
                        border-radius: 12px;
                        border: 1px solid #eee;
                        background: #d10010;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: "Inter";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.2px;
                    }

                    .glo {
                        width: 100%;
                        height: 110px;
                        border-radius: 12px;
                        border: 1px solid #eee;
                        background: #3eb040;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: "Inter";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.2px;
                    }

                    .mtn {
                        width: 100%;
                        height: 110px;
                        border-radius: 12px;
                        border: 1px solid #eee;
                        background: #f0bd2d;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: "Inter";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.2px;
                    }

                    .mobile9 {
                        width: 100%;
                        height: 110px;
                        border-radius: 12px;
                        border: 1px solid #eee;
                        background: #100f0e;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: "Inter";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.2px;
                    }
                    .brt {
                        width: 100%;
                        height: 110px;
                        border-radius: 12px;
                        border: 1px solid #eee;
                        background: #0e60e2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: "Inter";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.2px;
                    }

                    .airtimeText {
                        color: #1f1f1f;
                        font-family: "Inter";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.15px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    @media (max-width: 980px) {
        .AirtimeAndBillsInnerContent {
            flex-direction: column;

            .AirtimeAndBillsLeft {
                display: flex;
                align-items: center;
                gap: 5px;
                height: 80px;
                width: 100%;
                .AirtimeAndBillsBTN,
                .BRTBTN {
                    width: 130px;
                    margin-top: 0px;
                }
            }
            .AirtimeAndBillsRight {
                width: 100%;
                margin-top: 30px;
            }
        }
    }

    @media (max-width: 820px) {
        .AirtimeAndBillsInnerContent {
            flex-direction: column;

            .AirtimeAndBillsLeft {
                display: flex;
                align-items: center;
                gap: 5px;
                height: 80px;
                width: 100%;
                .AirtimeAndBillsBTN,
                .BRTBTN {
                    width: 130px;
                    margin-top: 0px;
                }
            }
            .AirtimeAndBillsRight {
                width: 100%;
                margin-top: 30px;
                .airtimeCardContainer {
                    flex-wrap: wrap;
                    .airtimeContainer {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@media (max-width: 628px) {
    .AirtimeAndBillsContent {
        width: 90%;

        .airtimeCardContainer {
            justify-content: center;
            .airtimeContainer {
                margin: 0 auto;
            }
        }
        @media (max-width: 425px) {
            .headerText {
                font-size: 35px;
            }
            .AirtimeAndBillsLeft {
                justify-content: space-between;
            }
        }
    }
}
