.familyCardMainContainer {
    margin-top: 30px;
}

.familyCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.familyCardInnerContainer {
    width: 30%;
    text-align: center;
}
.familyCardInnerContainer > img {
    object-fit: contain;
    width: 100%;
}

.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

@media (min-width: 482px) and (max-width: 768px) {
    .familyCardInnerContainer {
        width: 50%;
    }
}

@media (max-width: 481px) {
    .familyCardInnerContainer {
        width: 80%;
    }
}
