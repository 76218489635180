.ClientDashboard {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.chartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chartTitle {
        color: var(--neutral-900, #0f172a);
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.dropdownContainer {
    width: 120px;
}

tspan {
    font-size: 12px;
    color: var(--neutral-500, #64748b);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.625%;
}

.pieChartContainer {
    display: flex;
    //  border: 1px solid #e6e6e6;
    padding: 10px;
    justify-content: space-between;
    // flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.pieChart {
    min-width: 45%;

    border: 1px solid var(--shade-gray-2, #ced4df);
    background: #fff;
    padding: 10px;
}

.headerH2 {
    color: var(--neutral-900, #0f172a);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.headerP {
    color: #747272;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
    margin-bottom: 20px;
    margin-top: 5px;
}

.chartSubTitle {
    color: #747272;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.dropdownOutterContainer {
    display: flex;
    gap: 20px;
}

.queryLink {
    color: #000;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
    text-decoration: none;
}

rect {
    color: var(--neutral-900, #0f172a);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
}

@media (max-width: 1164px) {
    .pieChartHeader {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 680px) {
    .pieChartContainer {
        flex-direction: column;
        gap: 30px;
    }

    .pieChart {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
