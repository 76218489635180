.createValueSpendRegularCampaign {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.createValueHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.fieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.innerFieldContainer1 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContainer3 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.checkboxContainer1 {
    width: 30%;
}

.innerFieldContainer3 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.fieldContainer4 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.checkboxContainer4 {
    width: 30%;
}

.innerFieldContainer4 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContainer5 {
    margin-top: 30px;
}

.checkboxContainer5 {
    width: 30%;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #00bce1;
    border: 2px solid #00bce1;
}

.reset:hover {
    background-color: #00bce1;
    color: #fff;
    border: 2px solid #00bce1;
}

.iconContainer {
    display: flex;
}

.icon {
    cursor: pointer;
}

.paginationContainer {
    height: 50px;
    display: flex;
    align-items: center;
}

.disabledCampaigns {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 30px;
}

@media (max-width: 1500px) {
    .tableContainer1 {
        overflow-y: scroll;
    }

    .mainTable1 {
        width: 1800px;
    }
}

@media (max-width: 705px) {
    .fieldContainer1,
    .fieldContainer2,
    .fieldContainer3,
    .fieldContainer4 {
        flex-direction: column;
        gap: 30px;
    }

    .innerFieldContainer1,
    .innerFieldContainer3,
    .innerFieldContainer4,
    .checkboxContainer5 {
        width: 100%;
    }

    .buttonContainer {
        justify-content: space-between;
    }

    .checkboxContainer1,
    .checkboxContainer4 {
        width: 100%;
    }
}

@media (max-width: 430px) {
    .createValueHeader {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 349px) {
    .headerText {
        text-align: center;
    }
}
