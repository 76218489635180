.selectedCampaignContainer {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
}

.selectedCampaignContainerDisabled {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
    background-color: #f0f3f9;
}

.selectedItems {
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 100px;
    background: var(--perx-cyan, #00bce1);
    padding: 5px 10px;
    margin: 2px;
    height: 30px;
    justify-content: space-between;
    span {
        cursor: pointer;
    }
}

.selectedItemsDisbaled {
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 100px;
    background-color: #f0f3f9;
    padding: 5px 10px;
    margin: 2px;
    height: 30px;
    justify-content: space-between;
    cursor: not-allowed;
    pointer-events: none;
    span {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.selectedText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    p {
        color: var(--shade-white, #fff);
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.selectedTextDisabled {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    p {
        color: #919ba4;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.searchInput {
    display: flex;
    flex: 1;
    width: 0px;
    border: none;
    outline: none;
}

.searchInputDisabled {
    display: flex;
    flex: 1;
    width: 0px;
    border: none;
    outline: none;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #f0f3f9;
}
