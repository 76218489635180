.assignMembershipIDToFamilyMembers {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.noticeText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ff0000;
    margin-bottom: 5px;
}

.fieldContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.fieldContainer1 {
    width: 40%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 759px) {
    .fieldContainer {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
    }
    .fieldContainer1 {
        width: 100%;
    }

    .enterBTN {
        width: 100%;
    }

    .headerText {
        text-align: center;
    }
}
