.LoginTemplate {
    display: flex;
    flex-direction: row;
    height: 100dvh;
}

.LoginTemplateRight {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // padding-left: 20%;
}

.logoContainer {
    display: flex;
    align-items: center;
    width: 80%;
}

.formContainer {
    width: 60%;
}

.formInnerContainer {
    width: 100%;
}

.formHeader {
    color: #575757;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.48px;
    text-transform: capitalize;
}

.formInput1 {
    width: 100%;
    margin-top: 30px;
}

.formInput2 {
    width: 100%;
    margin-top: 20px;
}

.formInputText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.formInputText1 {
    color: #000;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.15px;
    text-transform: capitalize;
}

.formInputText2 {
    color: #0064fb;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    cursor: pointer;
}

.inputField {
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    width: 100%;
}

.formButtonContainer {
    margin-top: 30px;
    width: 100%;
}

.formButton {
    border-radius: 4px;
    border: 2px solid var(--btn-stroke, #222);
    background: #e2e2e2;
    width: 100%;
    color: #000;
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.15px;
}

.logoInnerContainer {
    width: 150px;
    height: 60px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.LoginTemplateLeft {
    background-image: url("../assets/images/loginTemplateImg.jpg");
    width: 50%;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginTemplateLeft > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.logoContainer {
    height: 100px;
}

.formContainer {
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcomeTextContainer {
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.36);
    width: 90%;
    padding: 20px;
}

.welcomeTextHeader {
    color: #fff;
    font-family: "Inter";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1px;
}

.welcomeTextBody {
    color: #fff;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.4px;
    margin-top: 10px;
}

.viewIcons {
    position: relative;
    top: -35px;
    right: -90%;
    span {
        cursor: pointer;
    }
}

@media (max-width: 1100px) {
    .formContainer {
        width: 80%;
    }
}

@media (max-width: 826px) {
    .formContainer {
        width: 90%;
    }
    .logoContainer {
        width: 90%;
    }
}

@media (max-width: 733px) {
    .LoginTemplate {
        flex-direction: column;
        height: auto;
    }
    .LoginTemplateRight {
        width: 100%;
        height: 500px;
        margin-bottom: 30px;
    }

    .logoContainer {
        width: 90%;
    }

    .LoginTemplateLeft {
        width: 100%;
        min-height: 500px;
    }
}
