.checked {
    height: 20px;
    width: 20px;
    background-color: #00bce1;
    border: 2px solid #00bce1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.uncheck {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #00bce1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
