.interactions {
    color: #bf8d2c;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

@media (max-width: 1500px) {
    .ticketTable {
        width: 1200px;
    }
}
