.searchBarContainer {
    width: 100%;
}

.searchField {
    display: flex;
    align-items: center;

    width: 100%;
    border: 1px solid #ced4df;
    height: 47px;
    padding-left: 10px;
}

.searchInput {
    height: 45px;
    background: #ffffff;
    /* border: 1px solid #ced4df; */
    // outline-color: #00bce1;
    padding: 10px;
    border: none;
    outline: none;
    width: 100%;
}
