.TemplateFooter {
    padding: 20px;
    background-color: #e6e6e6;
}

.innerTemplateFooter {
    width: 80%;
    margin: 0 auto;
    height: 270px;
}

.footerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    border-bottom: 2px solid #111b55;
}

.navLogo {
    height: 60px;
    width: 100px;
    display: flex;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.mediaIconContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.footerBottom {
    height: 100px;
    display: flex;
    align-items: center;
    color: var(--Black, #000);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}
