.tableInnerContainer {
    margin-top: 20px;
}

.thead {
    background-color: #ced4df;
}

th,
td {
    padding-left: 10px;
}

@media (max-width: 1080px) {
    .tableInnerContainer {
        width: 1200px;
    }
}
