.tD{
  border: none !important;
}

.tBODY{
    border-bottom: 2px solid #CED4DF;
}

.tBODY:last-child{
    border-bottom: none;
}

