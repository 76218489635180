.auctionMaster {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.noteText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    height: 50px;
    display: flex;
    align-items: center;
    color: #81c041;
}

.infoText {
    margin-bottom: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.paginationContainer {
    height: 50px;
    display: flex;
    align-items: center;
}
.approve{
    color: #5ED700;
    cursor: pointer;
    background-color: #fff;
   border: none !important;
    width: fit-content;
    font-weight: 500;
}
.disapprove{
    color: #FF0000;
    cursor: pointer;
}

@media (max-width: 823px) {
    .tableContainer {
        overflow-y: scroll;
    }

    .mainTable {
        width: 900px;
    }

    .headerText,
    .noteText {
        text-align: center;
    }
}
