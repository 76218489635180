.addFamilyMemberAtBranch {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.fieldInnerContainer1 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.fieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer3 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldInnerContainer3 {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.infoContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30px;
}

.infoContainer > h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.infoContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ff0000;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #00bce1;
    border: 2px solid #00bce1;
}

.reset:hover {
    background-color: #00bce1;
    color: #fff;
    border: 2px solid #00bce1;
}

.memberDetailsContainer {
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 30px;
    min-height: 100px;
    padding-bottom: 30px;
}

.memberName {
    p {
        color: var(--perx-blue, #28245f);
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        span {
            text-transform: uppercase;
        }
    }
}

.otherInfoContainer1 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.otherInfoContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.otherInfo1 {
    width: 30%;

    .textTitle {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .textValue {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 5px;
    }
}

.formMemberNumText {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        text-transform: capitalize;
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .deleteMemberForm {
        cursor: pointer;
    }
}

.addMoreBTN {
    margin-top: 30px;
    color: var(--perx-blue, #28245f);

    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    background-color: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 1146px) {
    .tableContainer {
        overflow-y: scroll;
    }
    .mainTable {
        width: 900px;
    }
}

@media (min-width: 990px) and (max-width: 1130px) {
    .fieldContainer2 {
        flex-wrap: wrap;
        gap: 30px;
    }
    .fieldInnerContainer1 {
        width: 45%;
    }
}

@media (max-width: 757px) {
    .detailContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }
    .otherInfoContainer1,
    .otherInfoContainer2 {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .otherInfo1 {
        width: 100%;
    }
}

@media (max-width: 570px) {
    .detailContainer {
        flex-direction: column;
    }

    .otherInfoContainer1,
    .otherInfoContainer2 {
        width: 100%;
    }
}

@media (max-width: 879px) {
    .fieldContainer1,
    .fieldContainer2 {
        flex-direction: column;
        gap: 30px;
    }

    .fieldInnerContainer1 {
        width: 100%;
    }
    .buttonContainer {
        justify-content: space-between;
    }
}

@media (max-width: 456px) {
    .fieldContainer3 {
        flex-direction: column;
        gap: 30px;
    }
    .fieldInnerContainer3 {
        width: 100%;
    }
}
