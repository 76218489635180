.Category {
    background-color: #e6e6e6;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerCategory {
    display: flex;
    gap: 10px;
}

/* Dropdown container */
.dropdown {
    position: relative;
}

/* Dropdown title */
.dropdownTitle {
    cursor: pointer;
    padding: 10px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 4px;

    .username {
        color: #000;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.15px;
    }
}

/* Dropdown options */
.dropdownOptions {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 150px;

    li {
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}

@media (max-width: 980px) {
    .Category {
        display: none;
    }
}
