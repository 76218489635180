.modalContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalTop {
    display: flex;
    justify-content: space-between;
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .cancelIcon {
        cursor: pointer;
    }
}

.modal {
    padding: 20px;
    background-color: #fff;
    width: 40%;
    min-height: 200px;
}

.modalTextContainer {
    margin-bottom: 20px;
    margin-top: 30px;
    h6 {
        color: var(--shade-black, #000);
        text-align: center;
        font-family: "Inter";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.closeModal {
    width: 100%;
}

@media (max-width: 783px) {
    .modal {
        width: 60%;
    }
}

@media (max-width: 522px) {
    .modal {
        width: 90%;
    }
}
