.imageDragAndDropContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;
}

.uploadImageContainer {
    display: flex;
    padding: 20px;
    border: 1px solid #ced4df;
    align-items: center;

    flex-grow: 1;
}

.dropBox {
    height: 47px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.browseBTN {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #99e4f3;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.browseBTN:hover {
    background-color: #fff;

    border: 2px solid #99e4f3 !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.submitImageBTN {
    width: 150px;
}

.submitImageBTN > button {
    width: 100%;
}

.dragDorpText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #919ba4;
}

@media (max-width: 574px) {
    .imageDragAndDropContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .uploadImageContainer,
    .submitImageBTN {
        width: 100%;
    }
}

@media (max-width: 395px) {
    .dragDorpText {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 17px;

        color: #919ba4;
    }

    .browseBTN {
        width: 130px;
    }
}
