.discountBenefitCampaign {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    justify-content: space-between;
}

.innerFieldContainer1,
.innerFieldContainer2 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContainer2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer3,
.fieldContainer4,
.fieldContainer5 {
    margin-top: 30px;
}

.innerFieldContainer3 {
    display: flex;
    flex-direction: column;
}
.innerFieldContainer4 {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.innerFieldContainer5 {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.fieldContainer6 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.innerFieldContainer6 {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.fieldContainer7 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.checkbox1 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.checkbox2 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.checkboxText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.monthsContainer,
.weeksContainer,
.daysContainer {
    margin-top: 30px;
}

.monthsMainContainer,
.mainWeeksContainer,
.mainDaysContainer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.innerMonthsContainer,
.innerDaysContainer {
    display: flex;
    gap: 5px;
    flex-basis: 100px;
    align-items: center;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

@media (max-width: 1088px) {
    .fieldContainer7 {
        gap: 15px;
        flex-direction: column;
    }

    .checkbox1 {
        width: 50%;
    }

    .checkbox2 {
        width: 80%;
    }
}

@media (max-width: 725px) {
    .fieldContainer1,
    .fieldContainer2,
    .fieldContainer3,
    .fieldContainer6 {
        flex-direction: column;
        gap: 30px;
    }
    .innerFieldContainer1,
    .innerFieldContainer2,
    .innerFieldContaine3,
    .innerFieldContainer4,
    .innerFieldContainer5,
    .innerFieldContainer6 {
        width: 100%;
    }

    .buttonContainer {
        justify-content: space-between;
    }
}

@media (max-width: 561px) {
    .fieldContainer7 {
        flex-direction: column;
        gap: 30px;
    }
    .checkbox1,
    .checkbox2 {
        width: 100%;
    }
}

@media (max-width: 399px) {
    .monthsMainContainer,
    .mainWeeksContainer,
    .mainDaysContainer {
        justify-content: space-between;
    }
}
