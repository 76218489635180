.assignMembershipIDContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer {
    display: flex;
    justify-content: space-between;
}

.fieldInnerContainer {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.buttonContainer {
    margin-top: 30px;
    height: 80px;
    display: flex;
    align-items: center;
}

.next {
    width: 150px;
}

@media (max-width: 765px) {
    .fieldContainer {
        flex-direction: column;
        gap: 30px;
    }
    .fieldInnerContainer {
        width: 100%;
    }
    .next {
        width: 200px;
    }
}

@media (max-width: 481px) {
    .next {
        width: 100%;
    }
}
