.profileNavOuterContainer {
    border-bottom: 1px solid #ced4df;
}
.profileNav {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.profileNav > div {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    height: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #000000;
}

.currentNav {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #df508b !important;
    border-bottom: 1px solid #df508b;
    cursor: pointer;
}

.cartTextContainer {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
}

.cartText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.backToCatalogue {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;

    text-align: right;
    text-decoration: none;
    color: #df508b;
}

.backToCatalogueButtom {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;

    text-align: right;
    text-decoration: none;
    color: #df508b;
    margin-top: 30px;
}

.backToCatalogue,
.backToCatalogueButtom:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;

    text-align: right;
    text-decoration: none;
    color: #df508b;
}

.itemsText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.deliverySection {
    margin-top: 50px;
}

.deliveryAddressContainer {
    margin-top: 50px;
}

.deliveryAddressContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.inputContainer1 {
    margin-top: 30px;
}

.inputContainer2 {
    margin-top: 30px;
}

.inputContainer1 {
    display: flex;
    justify-content: space-between;
}

.inputBox1 {
    display: flex;
    flex-direction: column;
}

.inputBox1 {
    width: 22%;
}

.shippingAddressContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.pointsContainer {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.pointsContainer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.pointsContainer > h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
}

.innerPointsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    h6 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #000000;
    }
}

.placeOrderBtn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.placeOrderBtn > button {
    width: 200px;
}
.tableContainer {
    margin-top: 20px;
}

.mainContainer {
    //margin-top: 30px;
    margin-top: 0;
    padding: 20px;
    width: 100%;
    border: 1px solid #ced4df;
}

.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

// toCardBtn

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.linkstyles {
    text-decoration: none;
    border-bottom: none;
}

.linkstyles:hover {
    text-decoration: none;
    border-bottom: none;
}

@media (max-width: 1097px) {
    .profileNavOuterContainer {
        overflow-y: scroll;
    }

    .tableContainer {
        overflow-x: scroll;
    }

    .profileNav {
        min-width: 1200px;
    }
}

@media (max-width: 1109px) {
    .inputContainer2 {
        flex-direction: column;
        gap: 30px;
    }

    .inputBox2 {
        width: 100%;
    }
}

@media (max-width: 839px) {
    .pointsContainer {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .inputContainer1 {
        flex-direction: column;
        gap: 30px;
    }

    .inputBox1 {
        width: 100%;
    }
}

@media (max-width: 449px) {
    .innerPointsContainer {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        align-items: unset;
        input {
            width: 100%;
        }
    }

    .placeOrderBtn {
        width: 100%;
    }

    .placeOrderBtn > button {
        width: 100%;
    }
}
