.footerContainer {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #ced4df;
}

.footerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.footerTextContainer {
    width: 48%;
}

@media (max-width: 564px) {
    .footerTextContainer {
        width: 100%;
    }

    .footerContainer {
        flex-direction: column;
        padding: 20px;
        gap: 10px;
    }

    .footerContainer {
        height: 100px;
    }
}
