.logQueryContainer {
    margin-top: 30px;
    padding-bottom: 30px;
}

.mainContainer {
    //margin-top: 30px;
    margin-top: 0;
    padding: 20px;
    width: 100%;
    border: 1px solid #ced4df;
}

.logQueryInnerContainer {
    margin-top: 30px;
}

.queryInputContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.queryTextareaContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.queryInputBox {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 30px;
}

.priorityInputOuterContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.priorityInputContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.priorityRadioOuterContainer {
    display: flex;
    flex-direction: column;
}

.priorityRadioContainer {
    width: 100%;
    display: flex;
    height: 47px;
    // justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}

sup {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #ff0000;
}

.myCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #00bce1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checked {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.uncheck {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.radioButton {
    display: flex;
    gap: 5px;
}

.queryBtn {
    width: 150px;
}

.queryBtnContainer {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: var(--perx-blue, #28245f);
    border: 2px solid var(--perx-blue, #28245f);
}

.reset:hover {
    background-color: var(--perx-blue, #28245f);
    color: #fff;
    border: 2px solid var(--perx-blue, #28245f);
}

.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

@media (max-width: 1112px) {
    .priorityInputOuterContainer {
        flex-wrap: wrap;
        gap: 30px;
    }
    .priorityInputContainer {
        width: 45%;
    }
}

@media (max-width: 862px) {
    .priorityInputOuterContainer {
        flex-direction: column;
        gap: 30px;
    }

    .priorityInputContainer {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .queryBtnContainer {
        display: flex;
        justify-content: space-between;
    }
    .queryInputContainer {
        flex-direction: column;
    }

    .queryInputBox {
        width: 100%;
    }

    .priorityRadioContainer {
        width: 100%;
        gap: 30px;
    }
}
