.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    border-radius: 4px;
    width: 400px;
    padding: 20px;
}
.modalEditAuction{
    background-color: #fff;
    border-radius: 4px;
    width: 80%;
    padding: 20px;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.modalTitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
}

.closeIcon {
    cursor: pointer;
    font-size: 20px;
}

.modalContent {
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.modalInnerContent {
    display: flex;
    align-items: center;
}

.modalInnerContent > div {
    width: 50%;
    height: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    border-top: 1px solid #ced4df;

    color: #000000;
}

.itemName {
    padding: 15px 5px;
    display: flex;
    align-items: center;
    background: #f0f3f9;
}

.itemValue {
    padding: 15px 5px;
    display: flex;
    align-items: center;
}

@media (max-width: 481px) {
    .modal, .modalEditAuction {
        width: 90%;
    }
    .modalOverlay{
    align-items:flex-start;
    overflow-y: scroll;
    }
}
