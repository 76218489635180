.PartnerProductAndServiceCategory {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.field1 {
    display: flex;
    justify-content: space-between;
}

.field3 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.field4 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer4 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.fieldContainer2 {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.radioButtonInnerContainer {
    width: 80%;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.defaultImageContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.uploadContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
}

.uploadInputContainer {
    flex-grow: 1;
}

.imageContainer {
    width: 80px;
    height: 80px;
}

.imageContainer > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.searchInputContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.searchInput {
    width: 80%;
}
.iconContainer {
    display: flex;
}

.icon {
    cursor: pointer;
}

.tableContainer {
    margin-top: 30px;
}

.pagination {
    margin-top: 30px;
    height: 50px;
    display: flex;
    align-items: center;
}

// @media (max-width: 1300px) {
//     .tableContainer {
//         overflow-x: scroll;
//     }

//     .mainTable {
//         width: 1350px;
//     }
// }

@media (max-width: 816px) {
    .field1,
    .field3 {
        flex-wrap: wrap;
        gap: 30px;
    }

    .fieldContainer1 {
        width: 45%;
    }
}

@media (max-width: 600px) {
    .field1,
    .field3,
    .field4 {
        flex-wrap: wrap;
        gap: 30px;
    }

    .fieldContainer1,
    .fieldContainer4,
    .radioButtonInnerContainer {
        width: 100%;
    }
    .buttonContainer {
        justify-content: space-between;
    }
    .headerText {
        text-align: center;
    }

    .tableContainer {
        overflow-x: scroll;
    }

    .mainTable {
        width: 650px;
    }
}

@media (max-width: 420px) {
    .searchInputContainer {
        flex-direction: column;
        gap: 20px;
    }

    .searchInput {
        width: 100%;
    }

    .search {
        width: 100%;
    }
}
