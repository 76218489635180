.eventsContainer {
    background: #fff;
    min-height: 100vh;
}

.eventContent {
    min-height: 500px;
    width: 80%;
    margin: 30px auto;
    .headerText {
        color: #222;
        text-align: center;
        font-family: "Inter";
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -1.08px;
    }

    .eventCardContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        flex-wrap: wrap;
        gap: 10px;
    }

    .eventCard {
        width: 300px;
        padding: 10px;
        transition: box-shadow 0.3s ease;
        padding: 1%;
        cursor: pointer;
        &:hover {
            box-shadow: 0 4px 6px rgba(203, 200, 200, 0.2);
        }

        .eventImg {
            width: 100%;
            height: 150px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .eventName {
            color: #222;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.2px;
            margin-top: 10px;
        }

        .timeContainer {
            display: flex;
            gap: 5px;
            align-items: center;
            margin: 5px 0px;
            p {
                color: #0072c3;
                font-family: "Inter";
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.13px;
            }
        }

        .desc {
            color: #7e7979;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.13px;
        }

        .points {
            color: #222;
            margin-top: 5px;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.13px;
        }
    }

    //   "  EVENT LOAD MORE BUTTON"

    .loadMoreContainer {
        height: 200px;
        margin-bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .loadMoreBTN {
            color: #000;
            text-align: center;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            border-radius: 4px;
            border: 1.5px solid #000;
            background: #f0bd2d;
            width: 250px;
        }

        .loadMoreBTNEmpty {
            color: #000;
            text-align: center;
            font-family: "Inter";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            border-radius: 4px;
            border: 1.5px solid #000;
            background: #fff;
            width: 250px;
        }
    }

    @media (max-width: 763px) {
        .eventCard {
            margin: 0 auto;
        }
    }
}

@media (max-width: 385px) {
    .eventContent {
        width: 90%;
    }
}
