table {
    width: 100%;
    // border-collapse: collapse;
}

thead {
    background-color: #ced4df;
}

th {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

td {
    border-bottom: 1px solid #ced4df;
    min-height: 100px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

.quantityField {
    width: 50px;
    outline: unset;
}

.removeButton {
    display: flex;
    // background-color: #bf8d2c;
    color: #bf8d2c;
    border: none;

    cursor: pointer;
    align-items: center;
}

@media (max-width: 1080px) {
    .cartTable {
        width: 1000px;
    }
}
