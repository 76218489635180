.companyDashboardGraphSetup {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.tableTop {
    border-top: 1px solid var(--shade-gray-2, #ced4df);
    background: var(--shade-gray-3, #f0f3f9);
    height: 50px;
    border-bottom: 1px solid var(--shade-gray-2, #ced4df);
    display: flex;
    justify-content: space-between;
}

.tableBody {
    background-color: #fff;
    height: 50px;
    border-bottom: 1px solid var(--shade-gray-2, #ced4df);
    display: flex;
    justify-content: space-between;
}

.leftContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.bodyLeftContainer {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
    gap: 20px;
}

.rightContainer {
    width: 430px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.innerLeftContainer {
    width: 300px;
    padding: 0px 10px;
}

.innerRighttContainer {
    width: 100px;
}
.checkboxContainer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.innerLeftTable {
    width: 300px;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}

.innerRightTable {
    width: 100px;
}

.radioContainer {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    width: 100px;
}

.colorContainer {
    display: flex;

    align-items: center;
    width: 110px;
    height: 30px;
    padding: 10px;
    border: 1px solid var(--shade-gray-2, #ced4df);
    background: var(--shade-white, #fff);
}

.belowTable {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.inputColorPicker {
    display: flex;
}

.dropdownInput {
    width: 30%;
}

.dropdownInputColor {
    // width: 100%;
    width: 30%;
}

.inputColorPicker {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--shade-gray-2, #ced4df);
    background: var(--shade-white, #fff);

    height: 47px;
    border-radius: 3px;
    padding: 10px;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

@media (max-width: 952px) {
    .setupInnerContainer {
        overflow-y: auto;
    }

    .setupMainContainer {
        width: 1000px;
    }

    .belowTable {
        flex-wrap: wrap;
        gap: 30px;
    }

    .dropdownInputColor,
    .dropdownInput {
        width: 45%;
    }
}

@media (max-width: 654px) {
    .belowTable {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .dropdownInputColor,
    .dropdownInput {
        width: 100%;
    }

    .buttonContainer {
        justify-content: space-between;
    }
}

@media (max-width: 376px) {
    .headerText {
        text-align: center;
    }
}
