.handleMemberQueryContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
}

.handleMemberQueryHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.formContainer {
    height: 230px;
    border: 1px solid #ced4df;
    margin-top: 30px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.HMQForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // flex-direction: unset !important;
}

.bvnContainer {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.memIdContainer {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.searchBtn {
    background: #00bce1;
    height: 47px;
    width: 15%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
}

.formHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.formInfo {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    // text-align: right;

    color: #ff0000;
}

.searchedResults {
    min-height: 200px;
    border: 1px solid #ced4df;
    margin-top: 50px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.handleMemberQueryTable {
    background-color: #f0f3f9;
    border-top: 1px solid #ced4df;
    border-bottom: 1px solid #ced4df;
    height: 50px;
}

th {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.firstColumn {
    padding-left: 10px;
}

td {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

tr {
    height: 50px;
}

.lastChild {
    color: #bf8d2c;
    cursor: pointer;
}

.tableMainContainer {
    width: 100%;
}

table {
    width: 100%;
}

@media (max-width: 699px) {
    .tableMainContainer {
        width: 100%;
        overflow-y: scroll;
    }

    .HMQTable {
        width: 800px;
    }

    .formContainer {
        height: 200px;
    }
}

@media (max-width: 536px) {
    .formContainer {
        height: 320px;
    }
    .HMQForm {
        flex-direction: column;
        align-items: unset;
        gap: 15px;
    }

    .bvnContainer {
        width: 100%;
    }
    .memIdContainer {
        width: 100%;
    }

    .searchBtn {
        width: 50%;
    }
}
