.card {
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    // border: 1px solid #ccc;
    border-radius: 4px;
    width: 25%;
    margin-top: 20px;
    // margin-right: 10%;
    // margin-left: 10%;
}

.cardImage {
    width: 100%;
    // max-height: 200px;
    object-fit: cover;
}

.cardName {
    margin-top: 10px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Shade/Black */

    color: #000000;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cardPrice {
    margin-top: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.radioButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 5px;
}

.radioButton {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    gap: 5px;

    // input[type="radio"] {
    //     display: none;
    // }

    &.active {
        color: #fff;

        input[type="radio"] {
            border: 1px solid #00bce1;
            background-color: #00bce1;
        }
    }
}

.pickupDropdown {
    margin-top: 10px;

    select {
        width: 100%;
        padding: 5px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}

.addToCartButton {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: #df508b;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    border: 2px solid #df508b;
}

.addToCartButton:hover {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: #df508b;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    border: 2px solid #df508b;
}

.addedToCart {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: #81c041;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    border: 2px solid #81c041;
}

.addedToCart:hover {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: #81c041;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    border: 2px solid #81c041;
}

.myCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #df508b;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checked {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.uncheck {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

@media (max-width: 645px) {
    .card {
        width: 300px;
    }
}

@media (min-width: 645px) and (max-width: 1237px) {
    .card {
        width: 250px;
    }
}
