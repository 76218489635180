.AssignMenusToCompanyUsers {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #00bce1;
    border: 2px solid #00bce1;
}

.reset:hover {
    background-color: #00bce1;
    color: #fff;
    border: 2px solid #00bce1;
}

.field {
    display: flex;
    justify-content: space-between;

    .fieldContainer {
        display: flex;
        flex-direction: column;
        width: 30%;
    }
}

.checkboxContainerTop,
.checkboxContainerButtom {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.searchSection {
    margin-top: 20px;
}

.checkboxMainContainerButtom {
    margin: 20px 0px;
}

.line1 {
    height: 50px;
    background-color: #f0f3f9;
    border-top: 1px solid #ced4df;
}

.line2 {
    height: 50px;
    border-top: 1px solid #ced4df;
}

.line3 {
    height: 50px;
    border-top: 1px solid #ced4df;
    display: flex;
    align-items: center;

    div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.line4 {
    height: 50px;
    border-top: 1px solid #ced4df;
    display: flex;
    align-items: center;
    padding-left: 40px;

    div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.line5 {
    height: 50px;
    border-top: 1px solid #ced4df;
    display: flex;
    align-items: center;
    padding-left: 70px;

    div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    p {
        color: var(--shade-black, #000);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.searchInputContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}

.searchInput {
    width: 80%;
}

.line6 {
    display: flex;
    height: 50px;
    border-top: 1px solid #ced4df;
}

.leftLine6 {
    width: 50%;
    background-color: #f0f3f9;
}

.rightLine6 {
    width: 50%;
    background-color: #ced4df;
    display: flex;
}

.innerRightLine6 {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--shade-black, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.line7 {
    display: flex;
    height: 50px;
    border-top: 1px solid #ced4df;
    .innerLeftLine7 {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 50%;
        p {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .innerRightLine7 {
        display: flex;
        width: 50%;
        background-color: #f0f3f9;

        .line7check {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.line8 {
    display: flex;
    height: 50px;
    border-top: 1px solid #ced4df;
    .innerLeftLine8 {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 50%;
        padding-left: 40px;
        p {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .innerRightLine8 {
        display: flex;
        width: 50%;
        background-color: #f0f3f9;

        .line7check {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.line9 {
    display: flex;
    height: 50px;
    border-top: 1px solid #ced4df;
    .innerLeftLine9 {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 50%;
        padding-left: 70px;
        p {
            color: var(--shade-black, #000);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .innerRightLine9 {
        display: flex;
        width: 50%;
        background-color: #f0f3f9;

        .line7check {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.buttonCheckBTNContainer {
    margin-top: 30px;
}

@media (max-width: 375px) {
    .checkboxMainContainerTop,
    .checkboxInnerContainerButtom {
        overflow-x: scroll;
    }

    .checkboxInnerContainerTop,
    .checkboxMainContainerButtom {
        width: 400px;
    }
}

@media (max-width: 705px) {
    .field {
        flex-direction: column;
        gap: 30px;

        .fieldContainer {
            width: 100%;
        }
    }
}

@media (max-width: 438px) {
    .line2 {
        height: unset;
    }
    .searchInputContainer {
        flex-direction: column;

        .searchInput {
            width: 100%;
        }
        button {
            width: 100%;
        }
    }
    .headerText {
        text-align: center;
    }
}
