.CompanyEmailTemplateMaster {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.field1 {
    display: flex;
    justify-content: space-between;
}

.field2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.field2Quill {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.fieldContainer1 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.emailPreviewField {
    border: 1px solid #a4a4a4;
    background: #fff;
    height: 500px;
    margin-top: 17.5px;
    padding: 20px;
    max-width: 100%;
}

.previewOutterContainer {
    overflow-y: scroll;
    height: 330px;
}

.previewContainer {
    min-height: 300px;
}

.previewTitle {
    color: #000;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.emailDetails {
    margin-top: 20px;
    color: #000;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    width: 100%;
}

.fieldContainer2 {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.fieldContainer2 {
    display: flex;
    margin-top: 30px;
    .radioButtonsContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .radioButtonContainer {
            gap: 5px;
            display: flex;
            align-items: center;
        }
    }
}

.radioImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .radioInnerImage {
        display: flex;

        justify-content: center;
    }
    img {
        width: 100px;
        height: 80px;
        object-fit: cover;
        object-position: center;
    }

    p {
        color: var(--shade-black, #000);
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.field3 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}
.radioButtonsContainer3 {
    display: flex;
    justify-content: space-between;
}

.radioButtonContainer3 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.field4 {
    display: flex;
    justify-content: space-between;
}

.fieldContainer4Left,
.fieldContainer4Right {
    width: 45%;
}

.fieldContainer4 {
    display: flex;
    flex-direction: column;
}

.fieldContainer4Down {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.fieldContainer4Right {
    border: 1px solid #a4a4a4;
    background: #fff;
    margin-top: 20px;
    padding: 20px;
    h5 {
        color: #000;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.field5 {
    margin-top: 30px;
    h5 {
        color: #000;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.innerField5 {
    h5 {
        color: #000;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.fieldContainer5 {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.innerFieldContainer5 {
    width: 45%;
    input {
        width: 100%;
    }
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.searchInputContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}
.tableSection {
    margin-top: 30px;
}

.searchInput {
    width: 80%;
}
.iconContainer {
    display: flex;
}

.icon {
    cursor: pointer;
}

.active {
    color: var(--perx-green, #81c041);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.inactive {
    color: var(--shade-black, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

@media (max-width: 1208px) {
    .innerTableContainer {
        overflow-x: scroll;
    }
    .mainTable {
        width: 1250px;
    }
}

@media (max-width: 797px) {
    .field1,
    .field2,
    .field3,
    .fieldContainer5 {
        flex-direction: column;
        gap: 30px;
    }

    .field2Quill,
    .field4 {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .fieldContainer1,
    .fieldContainer4Left,
    .fieldContainer4Right,
    .fieldContainer4,
    .innerFieldContainer5 {
        width: 100%;
    }

    .fieldContainer4Right {
        height: 400px;
    }
    .buttonContainer {
        justify-content: space-between;
    }

    .radioButtonsContainer3 {
        flex-wrap: wrap;
        gap: 30px;
    }
}

@media (max-width: 380px) {
    .searchInputContainer {
        flex-direction: column;
        gap: 20px;
    }

    .searchInput {
        width: 100%;
    }

    .searchBTN {
        width: 100%;
    }
}
