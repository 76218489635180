.calendarContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.mainContainer {
    //margin-top: 30px;
    margin-top: 0;
    padding: 20px;
    width: 100%;
    border: 1px solid #ced4df;
}

.calendarInnerContainer {
    display: flex;
    width: 30%;
    flex-direction: column;
}

.transactionDetailsInputContainer {
    margin-top: 30px;
}

.transactionTable {
    width: 100%;
    border: 1px solid #ced4df;
    margin-top: 30px;
    padding: 10px;
}

.paginationPage {
    height: 100px;
    display: flex;
    align-items: center;
}

.unActiveLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.unActiveLink:hover {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #000000;
    border: none;
    text-decoration: none;
}

.tableContainer {
    margin-top: 20px;
}

@media (max-width: 1200px) {
    .tableContainer {
        overflow-y: scroll;
    }
}

@media (max-width: 658px) {
    .calendarContainer {
        flex-direction: column;
        gap: 30px;
    }

    .calendarInnerContainer {
        width: 100%;
    }
}
