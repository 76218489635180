.colorPicker {
    background: transparent;
    border: 1px solid #ced4df;
    width: 36px;
    border: none;
    height: 36px;
}

.hexColor {
    text-transform: uppercase;
}
