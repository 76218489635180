.summaryContainer {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.fieldInnerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.th1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    background-color: #cde6b3;
    width: 800px;
}

.th2 {
    height: 47px;
    background-color: #e5d1ab;
    text-align: center;
}

.tableContainer {
    overflow-y: scroll;
}

.tableInnerContainer {
    width: 3000px;
    display: flex;
}

.mainTable1Container {
    width: 70%;
}

.mainTable1 {
    width: 100%;
}

.mainTable1ColorHead {
    width: 100%;
    height: 30px;
    background-color: #cde6b3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.mainTable2ColorHead {
    width: 100%;
    height: 30px;
    background-color: #e5d1ab;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.mainTable2Container {
    width: 30%;
}

.mainTable2 {
    width: 100%;
}

.activeStatus {
    background-color: #81c041;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
}

.inactiveStatus {
    background-color: #ff0000;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
}

.paginationContainer {
    height: 50px;
    display: flex;
    align-items: center;
}

@media (max-width: 552px) {
    .headerText {
        text-align: center;
    }
    .fieldInnerContainer {
        flex-direction: column;
    }
    .submit,
    .reset {
        width: 100%;
    }
}
