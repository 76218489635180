.CallCenterQueryType {
    margin-top: 60px;
    min-height: calc(100% - 60px);
    padding: 1.5rem;
    margin-bottom: 100px;
}

.headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.inputContainer {
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin-top: 30px;
}

.field1 {
    display: flex;
    justify-content: space-between;
}
.field2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.fieldContainer1 {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.middleText {
    color: var(--perx-green, #81c041);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0px;
    cursor: pointer;
}

.buttonContainer {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.submit {
    width: 150px;
}

.reset {
    width: 150px;
    background-color: #fff;
    color: #28245f;
    border: 2px solid #28245f;
}

.reset:hover {
    background-color: #28245f;
    color: #fff;
    border: 2px solid #28245f;
}

.tableSection {
    margin-top: 30px;
}

.searchInput {
    width: 80%;
}
.iconContainer {
    display: flex;
}

.icon {
    cursor: pointer;
}

.active {
    color: var(--perx-green, #81c041);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.inactive {
    color: var(--shade-black, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.searchInputContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}

@media (max-width: 632px) {
    .innerTableContainer {
        overflow-x: scroll;
    }
    .mainTable {
        width: 640px;
    }
}

@media (max-width: 670px) {
    .field1,
    .field2 {
        flex-direction: column;
        gap: 30px;
    }

    .fieldContainer1 {
        width: 100%;
    }
    .buttonContainer {
        justify-content: space-between;
    }
}

@media (max-width: 460px) {
    .searchInputContainer {
        flex-direction: column;
    }

    .searchInput,
    .searchBTN {
        width: 100%;
    }
}

@media (max-width: 395px) {
    .headerText {
        text-align: center;
    }
}
